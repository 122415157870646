export const transactionapi = `${process.env.REACT_APP_TRANSACTION_API_DOMAIN}${process.env.REACT_APP_TRANSACTION_API_PATH}`;

export const api = {
  accessToken: `${transactionapi}/payments/braintree/token`,
  checkout: `${transactionapi}/payments/braintree/checkout`,
  fetchTnxn: `${transactionapi}/transactions/fetchPurchaseByUser`,
  products: `${transactionapi}/products/byClient`,
  gpurchase: `${transactionapi}/offers/redeem`,
  authorizationToken: `${transactionapi}/auth/generateToken`,
  redeemVOD: `${transactionapi}/offers/redeemVOD`,
  statusVOD: `${transactionapi}/offers/statusVOD`
};
