import Worker from "workerize-loader!./reaction_worker"; // eslint-disable-line import/no-webpack-loader-syntax

export default function ReactionWorker() {
  this.worker = Worker();
  this.subscribers = [];
  this.reactions = [];

  this.getWorker = function () {
    return this.worker;
  };

  this.getReactions = function () {
    return this.reactions;
  };

  this.setReactions = function (reactions) {
    this.reactions = reactions;
    this.notifySubscribers(this.reactions);
  };

  this.addReaction = function (reaction) {
    if (this.reactions.length > 75) {
      this.reactions.shift();
    }
    this.reactions.push(reaction);
    this.notifySubscribers(this.reactions);
  };

  this.notifySubscribers = function (change) {
    this.subscribers.forEach((subscriber) => {
      subscriber(change);
    });
  };

  this.subscribe = function (itemToSubscribe) {
    if (this.subscribers.indexOf(itemToSubscribe) > -1) {
      return;
    }
    this.subscribers.push(itemToSubscribe);
  };

  this.unsubscribe = function (itemToUnsubscribe) {
    this.subscribers = this.subscribers.filter(
      (subscriber) => subscriber !== itemToUnsubscribe
    );
  };
}

export const reactionWorker = new ReactionWorker();

reactionWorker.getWorker().addEventListener("message", ({ data }) => {
  if (data.event === "reactionUpdate") {
    reactionWorker.addReaction(data.reaction);
  }
});
