import React, { useEffect, useState } from "react";
import { Shell, Carousel, Card, Text } from "@StixNext/mojito-ui";
import { Column, Grid, Row } from "@StixNext/mojito-ui";
import * as Images from "./common/watch-free";
import * as actions from "../actions/VideoOnDemand";
import { connect } from "react-redux";

const VideoOnDemand = (props) => {
  let { videoOnDemandList } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [category, setCategory] = useState([]);

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);
  const handleClick = (eventId = "FP2") => {
    props.history.push(`/voddetail/${eventId}`);
  };

  useEffect(() => {
    props.fetchVideoOnDemand();
  }, []);
  useEffect(() => {
    let cat = [];
    videoOnDemandList.map((video) => {
      let { Category } = video;
      if (Category) {
        cat.push(Category);
      }
    });
    let uniqueCat = cat.sort().filter((v, i, a) => a.indexOf(v) === i);
    setCategory(uniqueCat);
  }, [videoOnDemandList]);
  const filterCategory = (data, selectedCategory) => {
    let { Category = null } = data;

    if (Category) {
      let cat = Category.toString().split(",");
      let result = cat.filter((d) => d == selectedCategory.toString());
      if (result.length > 0) {
        return data;
      }
    }
  };
  return (
    <Shell.Body className="css-watch-free">
      {/* {category.map((cat, index) => {
        return ( */}
      <>
        <Grid>
          <Row className="ondemand_row">
            {/* <Column xl={4} lg={4} md={3} sm={6} xs={12}> */}
            {/* <Text as={"h5"} className={"upcoming-title"}>
                    {cat}
                  </Text> */}

            {videoOnDemandList.length > 0 &&
              videoOnDemandList.map((slide, index) => {
                let images = Images[`Image_100${slide.id.toString()}`]
                  ? Images[`Image_100${slide.id.toString()}`]
                  : Images[`defaultImage`];
                return (
                  <div>
                    <Card
                      key={index}
                      className={"video-ondemand-slides"}
                      onClick={() => handleClick(slide?.id)}
                      noPadding={false}
                      noBoxShadow={false}
                      noBorder={true}
                      noBorderRadius={true}
                    >
                      <img src={images} alt="" />
                      <div>
                        <Text
                          as="div"
                          className={"title-tiles vod-tiles watchfree-tiles"}
                        >
                          {slide?.title}
                        </Text>
                        <Text as="p" className="style-p">
                          {"Duration: " + slide?.duration + " secs"} <br />
                          {"Uploaded on: " + slide?.updatedDate} <br />
                        </Text>
                      </div>
                    </Card>
                  </div>
                  // <Card
                  //   key={index}
                  //   className={"video-slides"}
                  //   onClick={() => handleClick(slide?.id)}
                  //   noPadding={false}
                  //   noBoxShadow={false}
                  //   noBorder={true}
                  //   noBorderRadius={true}
                  // >
                  //   <img src={images} alt="" />
                  //   <div>
                  //     <Text as="div" className={"title-tiles vod-tiles"}>
                  //       {slide?.title}
                  //     </Text>
                  //     <Text as="p" className="style-p">
                  //       {"Duration: " + slide?.duration + " mins"} <br />
                  //       {"Uploaded on: " + slide?.updatedDate} <br />
                  //     </Text>
                  //   </div>
                  // </Card>
                );
              })}
            {/* </Column> */}
          </Row>
        </Grid>
        {/* {index !== category.length - 1 && (
          <Grid>
            <Row>
              <Column style={{ padding: "10px 0" }}>
                <dv className={"line-in-upcoming"} />
              </Column>
            </Row>
          </Grid>
        )} */}
      </>
      {/* })} */}
    </Shell.Body>
  );
};
const mapStateToProps = (state) => ({
  videoOnDemandList: state.VideoOnDemand.videoOnDemandList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchVideoOnDemand: () => dispatch(actions.fetchVideoOnDemand()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoOnDemand);
