import React, { useEffect } from "react";

import { connect } from "react-redux";
import {
  Shell,
  Stage,
  FeatureArtists,
  Video,
  Text,
  Button,
  BouncerContextHook,
  Card,
} from "@StixNext/mojito-ui";
import { Link } from "react-router-dom";
import useCommonI18n from "../utils/i18nHelpers";
import HANS from "../assets/images/isotope108.png";
import LAN from "../assets/images/jfj108.png";
import JOSHUA from "../assets/images/unusual108.png";
import ANDREW from "../assets/images/sistic108.png";
import MASTER from "../assets/images/mastercard108.png";
import KRISFLYER from "../assets/images/krisflyer108.png";
import banner from "../assets/images/banner_stage.jpg";
import banner_mob from "../assets/images/banner_mob.jpg";
import main1 from "../assets/images/stage_sq.jpg";
import { Column, Grid, Row } from "@StixNext/mojito-ui";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import Bravissimo from "../assets/images/bravissimo.jpg";
import { TransactionStatus } from "../components/BuyProductAndTransaction";
import ticket from "../assets/images/ticket.svg";
import passport from "../assets/images/passport.svg";
import power from "../assets/images/power.svg";
import poster from "../assets/images/poster.svg";
import boxset from "../assets/images/boxset.svg";
import totebag from "../assets/images/totebag.svg";

function Overview(props) {
  const value = BouncerContextHook();
  // let onClose = () => {
  //   props.changeTransactionValues("productPrice", 0);
  //   props.changeTransactionValues("showDropinComponent", false);
  //   props.changeTransactionValues("transactionStatus", 1);
  //   props.changeTransactionValues("checkconsent", false);
  //   props.changeTransactionValues("isOpen", false);
  //   localStorage.setItem("buttonClicked", false);
  // };
  let getDegitalPass = async (e) => {
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        value.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked")
        );
      }
    }, 1000);
  };

  useEffect(() => {
    // props.changeTransactionValues("transactionStatus", 11);
    // props.changeTransactionValues("isOpen", true);
    if (localStorage.getItem("grouppass") == "true") {
      props.changeTransactionValues("hideBuyButton", true);
      if (localStorage.getItem("logout") == "true") {
        props.changeTransactionValues("transactionStatus", 5);
        props.changeTransactionValues("isOpen", true);
      }
      localStorage.setItem("grouppass", false);
    }
    localStorage.setItem("grouppasscompleted", false);
  }, []);

  const { translate } = useCommonI18n();

  return (
    <>
      <Shell.Body className="overview_body_cnt">
        <Grid>
          <Row>
            <Column>
              <Stage
                className={"stage-class"}
                items={{
                  image: main1,
                  title:
                    "<span class='oopsland_txt'>JJ LIN「SANCTUARY FINALE」VIRTUAL CONCERT<br />REFUNDS, SINGLE VIEW BROADCAST & INCIDENT UPDATE</span>",
                  description: translate("overview.stage.discription"),
                  price: "S$12.00",
                  priceContent: translate("overview.stage.priceContent"),
                  // buttonText: translate("overview.stage.buttonText"),
                  // buttonOnClick: getDegitalPass,
                  // hideBuyButton: props.transactionDetails.hideBuyButton,
                  // transactionSuccessFlag:
                  //   props.transactionDetails.transactionSuccessFlag,
                }}
              ></Stage>{" "}
            </Column>
          </Row>
          {/* <Row>
            <Column>
              <div>
                <img src={banner} alt="icon" className="mike_img" />
                <img src={banner_mob} alt="icon" className="mike_mob_img" />
              </div>
            </Column>
          </Row> */}
        </Grid>
      </Shell.Body>
      {/* <div className="highlight-class highlight-class-color">
        <Grid>
          <Row>
            <Column>
              <Text as="div" className="highlight-title">
                HIGHLIGHT
              </Text>

              <Video
                className={"video-class"}
                items={{
                  url: "https://www.youtube.com/watch?v=aUoWnBJo91o",
                  thumbnail: Bravissimo,
                }}
              ></Video>
            </Column>
          </Row>
        </Grid>
      </div> */}
      {/* <div className="highlight-class ticket-tier-class">
        <Grid>
          <Row>
            <Column>
              <Text as="div" className="highlight-title">
                TICKET TIERS
              </Text>
            </Column>
          </Row>

          <Row>
            <Column
              xl={3}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="ticket-column"
            >
              <Card
                className="ticket-tier-card"
                noPadding={true}
                noBoxShadow={true}
                noBorder={false}
                noBorderRadius={false}
              >
                <div className="traveller-div">
                  TIME <br />
                  TRAVELLER <br />
                  標準時空旅人
                </div>
                <div className="ticket-detail">
                  <span className="ticket-head">
                    SGD 41{" "}
                    <span style={{ color: "#d82c2cdb" }}>
                      [ SOLD OUT | 全數售罄 ]
                    </span>
                  </span>
                  <br />
                  <div className="img-div">
                    <img src={ticket} />
                    <span style={{ fontSize: "12px" }}>
                      E-ticket{" "}
                      <span style={{ fontWeight: "bold" }}>電子門票</span>
                    </span>
                  </div>
                  <div className="double-div">
                    <img src={passport} />
                    <span style={{ fontSize: "12px" }}>
                      Finale Passport <br />
                      <span style={{ fontWeight: "bold" }}>
                        終點站 時空通行證
                      </span>
                    </span>
                  </div>
                </div>
              </Card>
            </Column>
            <Column
              xl={3}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="ticket-column"
            >
              {" "}
              <Card
                className="ticket-tier-card"
                noPadding={true}
                noBoxShadow={true}
                noBorder={false}
                noBorderRadius={false}
              >
                <div className="traveller-div">
                  LUMINOUS TIME <br />
                  TRAVELLER <br />
                  光芒時空旅人
                </div>
                <div className="ticket-detail">
                  <span className="ticket-head">
                    SGD 52{" "}
                    <span style={{ color: "#d82c2cdb" }}>
                      [ SOLD OUT | 全數售罄 ]
                    </span>
                  </span>
                  <br />
                  <div className="img-div">
                    <img src={ticket} />
                    <span style={{ fontSize: "12px" }}>
                      E-ticket{" "}
                      <span style={{ fontWeight: "bold" }}>電子門票</span>
                    </span>
                  </div>
                  <div className="double-div">
                    <img src={passport} />
                    <span style={{ fontSize: "12px" }}>
                      Finale Passport <br />
                      <span style={{ fontWeight: "bold" }}>
                        終點站 時空通行證
                      </span>
                    </span>
                  </div>
                  <div className="double-div">
                    <img src={power} />
                    <span style={{ fontSize: "12px" }}>
                      Interactive Luminous <br />
                      Wristband{" "}
                      <span style={{ fontWeight: "bold" }}>互動光芒手環</span>
                    </span>
                  </div>
                </div>
              </Card>
            </Column>
            <Column
              xl={3}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="ticket-column"
            >
              {" "}
              <Card
                className="ticket-tier-card"
                noPadding={true}
                noBoxShadow={true}
                noBorder={false}
                noBorderRadius={false}
              >
                <div className="traveller-div">
                  RESURGENT TIME <br />
                  TRAVELLER <br />
                  進階時空旅人
                </div>
                <div className="ticket-detail">
                  <span className="ticket-head">
                    SGD 72{" "}
                    <span style={{ color: "#d82c2cdb" }}>
                      [ SOLD OUT | 全數售罄 ]
                    </span>
                  </span>
                  <br />
                  <div className="img-div">
                    <img src={ticket} />
                    <span style={{ fontSize: "12px" }}>
                      E-ticket{" "}
                      <span style={{ fontWeight: "bold" }}>電子門票</span>
                    </span>
                  </div>
                  <div className="double-div">
                    <img src={passport} />
                    <span style={{ fontSize: "12px" }}>
                      Finale Passport <br />
                      <span style={{ fontWeight: "bold" }}>
                        終點站 時空通行證
                      </span>
                    </span>
                  </div>
                  <div className="double-div">
                    <img src={power} />
                    <span style={{ fontSize: "12px" }}>
                      Interactive Luminous <br />
                      Wristband{" "}
                      <span style={{ fontWeight: "bold" }}>互動光芒手環</span>
                    </span>
                  </div>
                  <div className="double-div">
                    <img src={poster} />
                    <span style={{ fontSize: "12px" }}>
                      Sancturary Tapestry <br />
                      Fabric Poster <br />
                      <span style={{ fontWeight: "bold" }}>
                        聖所掛上掛布海報
                      </span>
                      <br />
                      *Limited Edition{" "}
                      <span style={{ fontWeight: "bold" }}>*限量套裝</span>
                    </span>
                  </div>
                </div>
              </Card>
            </Column>
            <Column
              xl={3}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="ticket-column"
            >
              {" "}
              <Card
                className="ticket-tier-card"
                noPadding={true}
                noBoxShadow={true}
                noBorder={false}
                noBorderRadius={false}
              >
                <div className="traveller-div">
                  SANCTUARY <br />
                  COMPLETE TIME <br />
                  TRAVELLER <br />
                  頂級時空旅人
                </div>
                <div className="ticket-detail">
                  <span className="ticket-head">
                    SGD 192{" "}
                    <span style={{ color: "#d82c2cdb" }}>
                      [ SOLD OUT | 全數售罄 ]
                    </span>
                  </span>
                  <br />
                  <div className="img-div">
                    <img src={ticket} />
                    <span style={{ fontSize: "12px" }}>
                      E-ticket{" "}
                      <span style={{ fontWeight: "bold" }}>電子門票</span>
                    </span>
                  </div>
                  <div className="double-div">
                    <img src={passport} />
                    <span style={{ fontSize: "12px" }}>
                      Finale Passport <br />
                      <span style={{ fontWeight: "bold" }}>
                        終點站 時空通行證
                      </span>
                    </span>
                  </div>
                  <div className="double-div">
                    <img src={power} />
                    <span style={{ fontSize: "12px" }}>
                      Interactive Luminous <br />
                      Wristband{" "}
                      <span style={{ fontWeight: "bold" }}>互動光芒手環</span>
                    </span>
                  </div>
                  <div className="double-div">
                    <img src={poster} />
                    <span style={{ fontSize: "12px" }}>
                      Sancturary Tapestry <br />
                      Fabric Poster <br />
                      <span style={{ fontWeight: "bold" }}>
                        聖所掛上掛布海報
                      </span>
                      <br />
                      *Limited Edition{" "}
                      <span style={{ fontWeight: "bold" }}>*限量套裝</span>
                    </span>
                  </div>
                  <div className="double-div">
                    <img src={boxset} />
                    <span style={{ fontSize: "12px" }}>
                      Sanctuary Pictorial Box <br />
                      Set{" "}
                      <span style={{ fontWeight: "bold" }}>聖所寫真卡片組</span>
                    </span>
                  </div>
                  <div className="double-div">
                    <img src={totebag} />
                    <span style={{ fontSize: "12px" }}>
                      Sanctuary Eco Tote Bag <br />
                      <span style={{ fontWeight: "bold" }}>聖所裝進帆布袋</span>
                      <br />
                      *Special Edition{" "}
                      <span style={{ fontWeight: "bold" }}>*極限量套裝</span>
                    </span>
                  </div>
                </div>
              </Card>
            </Column>
          </Row>
          <Row>
            <Column>
              <span className="buy_footer">
                *Prices listed are inclusive of SISTIC booking fees and shipping
                fees
              </span>
            </Column>
          </Row>
        </Grid>
      </div> */}
      <div className="highlight-class sponsor-class">
        <Grid>
          <Row>
            <Column>
              <Text as="div" className="highlight-title">
                OUR SPONSORS
              </Text>
            </Column>
          </Row>
          <Row>
            <Column xl={2} lg={3} md={3} sm={6} className="spon_col">
              <div className="image_div">
                <img src={HANS} className="spono_img" />
              </div>
            </Column>
            <Column xl={2} lg={3} md={3} sm={6} className="spon_col">
              <div className="image_div">
                <img src={LAN} className="spono_img" />
              </div>
            </Column>
            <Column xl={2} lg={3} md={3} sm={6} className="spon_col">
              <div className="image_div">
                <img src={JOSHUA} className="spono_img" />
              </div>
            </Column>
            <Column xl={2} lg={3} md={3} sm={6} className="spon_col">
              <div className="image_div">
                <img src={ANDREW} className="spono_img" />
              </div>
            </Column>
            <Column xl={2} lg={3} md={3} sm={6} className="spon_col">
              <div className="image_div">
                <img src={MASTER} className="spono_img" />
              </div>
            </Column>
            <Column xl={2} lg={3} md={3} sm={6} className="spon_col">
              <div className="image_div">
                <img src={KRISFLYER} className="spono_img" />
              </div>
            </Column>
          </Row>
        </Grid>
        <Grid style={{ paddingTop: "25px", paddingBottom: "30px" }}>
          <Row>
            <Column xs={12}>
              <Link
                to="/FAQ"
                style={{
                  textDecoration: "none",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button primary className="feature_btn">
                  Click Here to View FAQ
                </Button>
              </Link>
            </Column>
          </Row>
        </Grid>
      </div>
      {/* <TransactionStatus
        isOpen={props.transactionDetails.isOpen}
        onClose={onClose}
        transactionSuccessFlag={props.transactionDetails.transactionSuccessFlag}
        transactionData={props.transactionDetails.transactionData}
        username={props.loginDetails.keycloak}
      /> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
