import moment from "moment";

const init = {
  programmesList: [],
  programme: [],
  dateSelected: moment().format("MM/DD/YYYY"),
};

export const Programmes = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_PROGRAMMES_VALUE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
