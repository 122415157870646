const init = {
  keycloak: {},
  authenticated: null,
  authenticateduser: null,
  hideBuyButton: false,
  showDropinComponent: false,
  authToken: null,
  accessToken: null,
  tokenExpired: "",
  logout: false,
  keycloakAccessToken: null,
  transactionSuccessFlag: null,
  isOpen: false,
  transactionData: null,
  user: {},
  checkconsent: false,
  checkValue: null,
};

export const Login = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_LOGIN_VALUE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
