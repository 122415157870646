import React, { useState, useEffect } from "react";
import { Modal } from "@StixNext/mojito-ui";
import { Column, Grid, Row } from "@StixNext/mojito-ui";
import { Text, Button, DropInPayment } from "@StixNext/mojito-ui";

export const VideoRestriction = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props]);

  return (
    <Modal isOpen={isOpen} size="large" onClose={props.onClose} ariaHideApp={false}>
      <Modal.Body>
        <div>
            <div>
                <Row>
                    <Column>
                        <Text as="h4" className="oops_txt">
                            {props.errorMessage}
                        </Text>
                        <br />
                    </Column>
                </Row>
            </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};