import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Shell,
  VideoPlayer,
  FeatureArtists,
  Grid,
  Row,
  Column,
  Text,
  Button,
  BouncerContextHook,
  CloseWhiteIcon,
  DownArrowIcon,
  SisticLivePlayerAlt,
  SisticLivePlayer,
  AddOn,
  Reaction,
  HahaIcon,
  HeartReactionIcon,
  LikeIcon,
  InformationIcon,
  Toggle,
} from "@StixNext/mojito-ui";
import ReactHtmlParser from "react-html-parser";
import Iframe from "react-iframe";
import Moment from "react-moment";
import livestreamdot from "../assets/images/icons/livestream_dot.svg";
import Book from "../assets/images/book.png";
import BookStore from "../assets/images/bookstore.jpg";
import Donation from "../assets/images/donation.png";
import SecondScreen from "../assets/images/second_screen.png";
import Arrow from "../assets/images/leftarrow.png";
import ChatIco from "../assets/images/icons/chat_icon_black.svg";
import QAIco from "../assets/images/icons/qa_icon_black.svg";
import PollIco from "../assets/images/icons/poll_icon_black.svg";
import AddonIco from "../assets/images/icons/star_icon_black.svg";
import { TransactionStatus } from "../components/BuyProductAndTransaction";
import Keycloak from "keycloak-js";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import * as progrmmeActions from "../actions/Programmes";
import history from "../stores/history";
import Chat from "@StixNext/mojito-ui/dist/Chat/Chat";
import VideoOverlay from "../assets/images/video_overlay.jpg";
import LiveChat from "./LiveChat";
import LiveReaction from "./LiveReaction";

function VideoDetail(props) {
  const { history, location = {} } = props;
  const loginContext = BouncerContextHook();
  const [ChatOrQA, setChatOrQA] = useState("addon");
  const [play, setPlay] = useState(false);
  const [end, setEnd] = useState(false);
  const [start, setStart] = useState(false);
  const [legalAge, setLegalAge] = useState(false);
  const [hideAgeOverlay, setHideAgeOverlay] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [fullscreenEnable, setFullScreenEnable] = useState(false);
  const [addOnSection, setAddOnSection] = useState([]);
  const [addonStart, setAddonStart] = useState(false);
  const [addonStop, setAddonStop] = useState(false);
  const [stopChat, setStopChat] = useState(false);
  // const [messageList, setMessageList] = useState([]);

  // const emojiList = {
  //   1: <HahaIcon />,
  //   2: <HeartReactionIcon />,
  //   3: <LikeIcon />,
  // };

  // const sendReaction = (e) => {
  //   setMessageList((state) => [
  //     ...state,
  //     {
  //       text: e,
  //       name: "Test user",
  //       uid: `0JhgEJCBTgNUyusFDTBWnJ8akJs2`,
  //       id: `gqTnmRUPeNXR1acWGCMe-${e}`,
  //     },
  //   ]);
  // };

  let interval = null;
  let addOnArray = [];
  function myFunction(startDateTime, endDateTime) {
    interval = setInterval(function () {
      var serverDate = moment(new Date(moment.utc().format()));
      var indiaDate = moment(new Date(moment.utc(startDateTime).format()));
      setStart(true);

      if (indiaDate.diff(serverDate, "seconds") < 1) {
        if (!play) {
          setPlay(true);
          setStart(false);
        }
        clearInterval(interval);
      }
    }, 1000);
  }
  // let onClose = () => {
  //   props.changeTransactionValues("productPrice", 0);
  //   props.changeTransactionValues("showDropinComponent", false);
  //   props.changeTransactionValues("transactionStatus", 1);
  //   props.changeTransactionValues("checkconsent", false);
  //   props.changeTransactionValues("isOpen", false);
  //   localStorage.setItem("buttonClicked", false);
  // };

  let getDegitalPass = async (e) => {
    localStorage.setItem("buttonClicked", true);
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        loginContext.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked")
        );
      }
    }, 1000);
  };
  useEffect(() => {
    // setPlay(true);
    // props.changeTransactionValues("transactionStatus", 11);
    // props.changeTransactionValues("isOpen", true);
    let progammeId = props.match.params.id;
    props.fetchProgrammeDetail(progammeId);
    return () => {
      clearInterval(interval);
      props.changeProgrammesValues("programme", []);
    };
  }, []);
  useEffect(() => {
    if (props.programmeDetails.programme.length > 0) {
      myFunction(
        props.programmeDetails.programme[0].startDateTimeUTC,
        props.programmeDetails.programme[0].endDateTimeUTC
      );
      if (
        props.programmeDetails.programme[0].ChatFlag &&
        props.programmeDetails.programme[0].QAFlag
      ) {
        setChatOrQA("chat");
      } else if (props.programmeDetails.programme[0].ChatFlag) {
        setChatOrQA("chat");
      } else if (props.programmeDetails.programme[0].QAFlag) {
        setChatOrQA("QA");
      }
    }
  }, [props.programmeDetails.programme]);

  const onToggle = () => setStopChat(!stopChat);

  return (
    <>
      {props.programmeDetails.programme.length > 0 &&
        props.programmeDetails.programme.map((value, key) => {
          return (
            <div className="row vodContainer">
              <div className="container">
                <Link
                  to={
                    localStorage.getItem("prevPath")
                      ? localStorage.getItem("prevPath")
                      : "/"
                  }
                  className="title"
                >
                  <img src={Arrow} alt="icon" />
                  <Text as="captionblock" className="titletext">
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexFlow: "row",
                        alignItems: "center",
                        columnGap: "6px",
                      }}
                    >
                      Live stream <img src={livestreamdot} alt="icon" />
                    </div>
                    {value.title}
                    <br />
                    <div style={{ fontSize: "13px", fontWeight: "100" }}>
                      <Moment format="DD MMM YYYY">
                        {value.startDateTime}
                      </Moment>{" "}
                      <Moment format="LT">{value.startDateTime}</Moment>
                      {" @ "}
                      <span style={{ fontWeight: "bold" }}> SISTIC LIVE</span>
                    </div>
                  </Text>
                </Link>
                <div
                  className={`wholediv 
                 ${
                   loginContext.sessionRestrictor ||
                   props.loginDetails.authenticated === null ||
                   props.loginDetails.authenticated === false
                     ? "unavailable"
                     : ""
                 }
                `}
                >
                  <div
                    className="videodiv disablechat"
                    // className={`videodiv ${
                    //   value.ChatFlag || value.QAFlag || value.addOn.length > 0
                    //     ? ""
                    //     : "disablechat"
                    // }`}
                  >
                    {/* <div className="frame_div"> */}
                    {!loginContext.sessionRestrictor &&
                      props.loginDetails.authenticated &&
                      // props.transactionDetails.hideBuyButton &&
                      play &&
                      !start && (
                        <>
                          <SisticLivePlayerAlt
                            className="live-player"
                            playerItems={{
                              src: value.VideoAssetURL,
                              stopChat: stopChat,
                              reactionContent: (
                                <LiveReaction
                                  highlight={false}
                                  session={props.match.params.id}
                                  stopChat={stopChat}
                                />
                              ),
                              chatContent: (
                                <LiveChat
                                  highlight={false}
                                  session={props.match.params.id}
                                  stopChat={stopChat}
                                />
                              ),
                            }}
                          />
                          <div className="full-interaction">
                            <Toggle
                              className="toggle-chat"
                              onToggled={onToggle}
                              defaultState={true}
                            />
                            <p>
                              Turn on full interactive experience 开启全互动体验
                            </p>
                          </div>
                          <div className="full-interaction-mobile">
                            <Toggle
                              className="toggle-chat"
                              onToggled={onToggle}
                              defaultState={true}
                            />
                            <p>Full Interaction</p>
                            <div className="tooltip">
                              <InformationIcon />
                              <span className="tooltiptext">
                                {`Turn on the "Full Interaction" mode to enjoy the full interactive experience`}
                              </span>
                            </div>
                          </div>
                        </>
                      )}

                    {/* </div> */}
                    {/* <div
                      className={`secondscreen_div ${
                        value.ChatFlag || value.QAFlag ? "" : "disablechat"
                      } ${fullscreenEnable ? "fullsecond_screen" : ""}`}
                    >
                      <div
                        className="icon_div"
                        onClick={() => {
                          if (fullscreenEnable) {
                            setShowDropdown(true);
                          } else {
                            setShowDropdown(false);
                          }
                        }}
                        onMouseEnter={() => {
                          if (fullscreenEnable) {
                            setShowDropdown(true);
                          } else {
                            setShowDropdown(false);
                          }
                        }}
                      >
                        <h6 className="screen_title">second screen</h6>
                        <img
                          src={Arrow}
                          className={`arrow_div ${
                            fullscreenEnable && !showDropdown ? "show" : ""
                          }`}
                        />
                        <CloseWhiteIcon
                          className={`close_div ${
                            fullscreenEnable && showDropdown ? "show" : ""
                          }`}
                          onClick={() => setShowDropdown(false)}
                        />
                      </div>
                      <div
                        className="mob_icon_div"
                        onClick={() => {
                          if (!showDropdown) {
                            setShowDropdown(true);
                          } else {
                            setShowDropdown(false);
                          }
                        }}
                      >
                        <h6 className="screen_title">second screen</h6>
                        <img
                          src={Arrow}
                          className={`arrow_div ${!showDropdown ? "show" : ""}`}
                        />
                        <CloseWhiteIcon
                          className={`close_div ${showDropdown ? "show" : ""}`}
                          onClick={() => setShowDropdown(false)}
                        />
                      </div>
                      <div
                        id="scroll_div"
                        className={`scroll_div ${
                          showDropdown ? "scorll_show" : ""
                        } ${
                          fullscreenEnable
                            ? showDropdown
                              ? "fullscreen_show"
                              : "fullscreen_close"
                            : ""
                        }`}
                      >
                        <p className="content_div">
                          This is the section to suppliment the event , it will
                          be in running text
                        </p>
                        <div className="thumb_div">
                          <img
                            src={SecondScreen}
                            className="img_div"
                            alt="icon"
                          />
                          <span className="thumb_cont">
                            It can be thumbnail, and also supporting text
                          </span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {props.loginDetails.authenticated === null ||
                  props.loginDetails.authenticated === false ? (
                    // ||
                    // props.transactionDetails.hideBuyButton === false
                    // !value.ChatFlag && !value.QAFlag ? (
                    <div className="mobchatalt"></div>
                  ) : (
                    // ) : (
                    //   ""
                    // )
                    ""
                  )}
                  {/* {value.ChatFlag || value.QAFlag || value.addOn.length > 0 ? (
                    <div className="chatsection">
                      <div
                        style={{
                          height: "477px",
                          background: "white",
                        }}
                      >
                        <div className="chatQAMenu">
                          {value.addOn.length > 0 && (
                            <div className="chatMenuTitle">
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setChatOrQA("addon")}
                              >
                                <span
                                  className={`chatMenuTitleSpan ${
                                    ChatOrQA === "addon" && "chatQAMenuActive"
                                  }`}
                                >
                                  Add-Ons
                                </span>
                                <img
                                  className={`chatMenuTitleImage ${
                                    ChatOrQA === "addon" && "chatMenuActive"
                                  }`}
                                  src={AddonIco}
                                />
                              </span>
                            </div>
                          )}
                          {value.ChatFlag && (
                            <div className="chatMenuTitle">
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setChatOrQA("chat")}
                              >
                                <span
                                  className={`chatMenuTitleSpan ${
                                    ChatOrQA === "chat" && "chatQAMenuActive"
                                  }`}
                                >
                                  Chat
                                </span>
                                <img
                                  className={`chatMenuTitleImage ${
                                    ChatOrQA === "chat" && "chatMenuActive"
                                  }`}
                                  src={ChatIco}
                                />
                              </span>
                            </div>
                          )}
                          {value.QAFlag && (
                            <div className="chatMenuTitle">
                              <span onClick={() => setChatOrQA("QA")}>
                                <span
                                  className={`chatMenuTitleSpan ${
                                    ChatOrQA === "QA" && "chatQAMenuActive"
                                  }`}
                                >
                                  Q&A
                                </span>
                                <img
                                  className={`chatMenuTitleImage ${
                                    ChatOrQA === "qa" && "chatMenuActive"
                                  }`}
                                  src={QAIco}
                                />
                              </span>
                            </div>
                          )}
                        </div>
                        <span className="menuborder"></span>
                        {ChatOrQA === "chat" ? (
                          <div>
                            <Iframe
                              url={
                                process.env.PUBLIC_URL +
                                `/chat.html?${value.ChatSessionId}`
                              }
                              width="100%"
                              height="413px"
                              id="myId"
                              className="pigeonhole-iframe"
                              display="initial"
                              position="relative"
                            />
                          </div>
                        ) : ChatOrQA === "QA" ? (
                          <div>
                            <Iframe
                              url={
                                process.env.PUBLIC_URL +
                                `/qa.html?${value.QASessionId}`
                              }
                              width="100%"
                              height="413px"
                              id="myId"
                              className="pigeonhole-iframe"
                              display="initial"
                              position="relative"
                            />
                          </div>
                        ) : (
                          ChatOrQA === "addon" && (
                            <AddOn className="addondiv" items={value.addOn} />
                          )
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}
                  {!play && start && (
                    <div
                      // style={{
                      //   backgroundImage: `url(${VideoOverlay})`,
                      // }}
                      className={"text_div disablechat"}
                    >
                      <img src={VideoOverlay} className="video_over_img" />
                      <p className="video_over_cont">
                        Livestream will start on
                        <br />
                        <Moment format="DD MMM YYYY">
                          {value.displayDateTime}
                        </Moment>{" "}
                        | <Moment format="LT">{value.displayDateTime}</Moment>{" "}
                        SGT
                      </p>
                    </div>
                  )}
                  {loginContext.sessionRestrictor ||
                  props.loginDetails.authenticated === null ||
                  props.loginDetails.authenticated === false ? (
                    // ||
                    // props.transactionDetails.hideBuyButton === false
                    <div className="wrap_div">
                      <Text as="h4" className="wrap_title">
                        {
                          "This video is only available upon login with your Access Pass"
                        }
                        <br />
                        {"請登入你的帳號並用已購買的時空通行證連結觀看演唱會"}
                      </Text>
                      <div>
                        <Text as="h6" className="wrap_pass">
                          {"Don't Have an Access Pass yet?"}
                          <br />
                          {"還沒購買時空通行證嗎"}
                        </Text>
                        <Button
                          primary
                          className="video_btn"
                          onClick={(e) => {
                            getDegitalPass(e);
                          }}
                        >
                          Get your Access Pass here
                        </Button>
                      </div>
                      {loginContext.sessionRestrictor ||
                      props.loginDetails.authenticated === null ||
                      props.loginDetails.authenticated === false ? (
                        <div>
                          <Text as="h6" className="wrap_pass">
                            {"Already Have an Access Pass?"}
                            <br />
                            {"已經購買時空通行證了"}
                          </Text>
                          <Button
                            primary
                            className="video_btn"
                            onClick={(e) => {
                              localStorage.setItem("logout", false);
                              // props.changeTransactionValues(
                              //   "transactionStatus",
                              //   10
                              // );
                              // props.changeTransactionValues("isOpen", true);
                              setTimeout(() => {
                                loginContext.loginKeycloak();
                              }, 1000);
                              // loginContext.loginKeycloak();
                            }}
                          >
                            Click here to login
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="synopiscontainer">
                <Row>
                  <Column>
                    <Text as="h4" className="oops_txt">
                      TECHNICAL FAULT!
                    </Text>
                    <br />
                    <Text as="h5" className="stream_txt">
                      SISTIC is currently experiencing technical difficulties
                      for the live stream of JJ Lin「Sanctuary Finale」Virtual
                      Concert. Please give us a few moments as we work to resume
                      this live stream and ensure that you will be able to view
                      the full concert. We apologise unreservedly to all those
                      affected by tonight’s technical difficulties.
                    </Text>
                    <br />
                    <Text as="h5" className="stream_txt">
                      SISTIC
                      深表歉意，由於技術方面的困難，觀眾可能錯過了部分演出。我們目前正在制定一個計劃，讓觀眾都能再次趕上完整的線上演唱會。
                    </Text>
                    <br />
                  </Column>
                </Row>
                <div className="synopisdiv">
                  <div className="synopissection">
                    <Text as="h6" className="synopsis">
                      Short Introduction
                    </Text>
                    <Text as="p">
                      {value.writeUp &&
                        ReactHtmlParser(
                          value.writeUp.replace(/(?:\r\n|\r|\n)/g, "<br />")
                        )}
                    </Text>
                    <div className="bookwebsection">
                      <div className="booksection">
                        {value.donationLink && (
                          <a
                            href={value.donationLink}
                            target="_blank"
                            style={{
                              display: "flex",
                              textDecoration: "none",
                            }}
                          >
                            <img
                              src={Donation}
                              alt="icon"
                              style={{
                                width: "150px",
                                height: "150px",
                                borderRadius: "50%",
                              }}
                            />
                            <Text as="p" className="booktext">
                              Click here to donate <br />
                              to our giving.sg
                            </Text>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="chatsection">
                    <div className="videoDetailFeature">
                      <Text as="h4" className="artist">
                        <b>FEATURE ARTIST</b>
                      </Text>
                      <Text as="h6" className="festure-presenter">
                        {value.featuredPresenters &&
                          ReactHtmlParser(
                            value.featuredPresenters.replace(
                              /(?:\r\n|\r|\n)/g,
                              "<br />"
                            )
                          )}
                      </Text>
                      <Text as="p">
                        {value.presentersWriteup &&
                          ReactHtmlParser(
                            value.presentersWriteup.replace(
                              /(?:\r\n|\r|\n)/g,
                              "<br />"
                            )
                          )}
                      </Text>
                    </div>
                    {/* <div className="videoDetailFeature">
                      <Text as="h6">
                        <b>Moderator</b>
                      </Text>
                      <Text as="p">
                        {value.moderator &&
                          ReactHtmlParser(
                            value.moderator.replace(/(?:\r\n|\r|\n)/g, "<br />")
                          )}
                      </Text>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="bookcontainer">
                <div className="bookdiv">
                  <div className="bookmobsection">
                    {value.bookstoreLink && (
                      <a
                        href={`https://${value.bookstoreLink}`}
                        target="_blank"
                        style={{
                          display: "flex",
                          paddingLeft: "15px",
                          paddingBottom: "32px",
                          textDecoration: "none",
                        }}
                      >
                        <img
                          src={BookStore}
                          alt="icon"
                          style={{ width: "110px", height: "170px" }}
                        />
                        <Text as="p" className="booktext">
                          Visit swfbooks.com
                        </Text>
                      </a>
                    )}
                    {value.donationLink && (
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "15px",
                          paddingBottom: "32px",
                        }}
                      >
                        <img
                          src={Donation}
                          alt="icon"
                          style={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                          }}
                        />
                        <Text as="p" className="booktext">
                          Click here to donate <br />
                          to our giving.sg
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      {/* <TransactionStatus
        isOpen={props.transactionDetails.isOpen}
        onClose={onClose}
        transactionSuccessFlag={props.transactionDetails.transactionSuccessFlag}
        transactionData={props.transactionDetails.transactionData}
        username={props.loginDetails.keycloak}
      /> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    programmeDetails: state.Programmes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProgrammesValues: (f, e) =>
    dispatch(progrmmeActions.changeProgrammesValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
  fetchProgrammeDetail: (id) =>
    dispatch(progrmmeActions.fetchProgrammeDetail(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoDetail);
