import Worker from "workerize-loader!./message_worker"; // eslint-disable-line import/no-webpack-loader-syntax

export default function MessageWorker() {
  this.worker = Worker();
  this.subscribers = [];
  this.messages = [];

  this.getWorker = function () {
    return this.worker;
  };

  this.getMessages = function () {
    return this.messages;
  };

  this.setMessages = function (messages) {
    this.messages = messages;
    this.notifySubscribers(this.messages);
  };

  this.addMessage = function (message) {
    if (this.messages.length > 100) {
      this.messages.shift();
    }
    this.messages = [...this.messages, message];
    this.notifySubscribers(this.messages);
  };

  this.notifySubscribers = function (change) {
    this.subscribers.forEach((subscriber) => {
      subscriber(change);
    });
  };

  this.subscribe = function (itemToSubscribe) {
    if (this.subscribers.indexOf(itemToSubscribe) > -1) {
      return;
    }
    this.subscribers.push(itemToSubscribe);
  };

  this.unsubscribe = function (itemToUnsubscribe) {
    this.subscribers = this.subscribers.filter(
      (subscriber) => subscriber !== itemToUnsubscribe
    );
  };
}

export const messageWorker = new MessageWorker();

messageWorker.getWorker().addEventListener("message", ({ data }) => {
  if (data.event === "chatUpdate") {
    messageWorker.addMessage(data.message);
  }

  if (data.event === "chatUpdateLast") {
    messageWorker.setMessages(data.messages);
  }
});