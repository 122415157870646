import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Text, Button, Checkbox } from "@StixNext/mojito-ui";
import Moment from "react-moment";

import banner from "../../assets/images/trans_success.jpg";

import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";

function TransactionCondition(props) {
  return (
    <>
      <div>
        <Row>
          <Column xl={12} xs={12}>
            <Text as="h4" className="transaction_header">
              You Are Buying JJ LIN [SANCTUARY FINALE] Virtual Concert - $
              {Number(props.transactionDetails.productPrice).toFixed(2)}
              <br />
              您正在購買林俊傑「聖所FINALE」終點站線上演唱會門票 - $
              {Number(props.transactionDetails.productPrice).toFixed(2)}
            </Text>
          </Column>
        </Row>
      </div>
      <div className="consent_div">
        <Text as="h6">
          {"Conditions of Sale, Use of Personal Data:"}
          <br />
          {"銷售條件、個人數據的使用："}
        </Text>
        <div className="condition_div">
          <label className="base-label">
            <Checkbox
              id="unchecked"
              checked={props.transactionDetails.checkconsent}
              onChange={(e) => {
                props.changeTransactionValues("checkconsent", e.target.checked);
              }}
            >
              {" "}
            </Checkbox>{" "}
            <span>
              I have read and accept{" "}
              <a
                className="link_condition"
                href="https://www.sistic.com.sg/terms-and-conditions"
                target="_blank"
              >
                SISTIC Terms & Conditions of Sale
              </a>{" "}
              and consent to the collection, use and disclosure of my personal
              data by SISTIC:
              <br />
              我已閱讀並接受SISTIC的銷售條款和條件，以及同意SISTIC收集、使用和披露我的個人數據:
            </span>
          </label>
        </div>
        <ol className="condition_ol">
          <li className="condition_li">
            in SISTIC’s own capacity as ticket seller, for use and disclosure by
            SISTIC and its agents, for the purpose of ticket sale processing and
            issuance;
            <br /> SISTIC作為指定售票系統,
            供SISTIC及其代理商使用，以進行票務處理和發行；
          </li>
          <br />
          <li className="condition_li">
            as data intermediary and ticketing agent of the event promoter, for
            use and disclosure by the promoter and its agents, for the purpose
            of event management; and <br />
            作為活動主辦方的數據中介和票務代理,
            供活動主辦方及其代理商使用和披露, 以進行活動管理; 以及
          </li>
          <br />
          <li className="condition_li">
            as data intermediary of the venue owner/manager hosting the event,
            for use and disclosure by the venue owner/manager and its agents,
            for the purpose of managing venue admission and seating in
            accordance with SISTIC's Privacy Policy. No refund or exchange of
            tickets is allowed once your booking is confirmed. <br />
            作為場地持有者/活動管理者的數據中介,
            供場地持有者/管理者及其代理商使用和披露，以SISTIC的隱私政策,
            進行場地 的入場和座位管理。購票經確認後，恕不退款或退換門票。
          </li>
          <br />
          {/* <li className="condition_li">
            in accordance with SISTIC’s{" "}
            <a
              className="link_condition"
              href="https://www.sistic.com.sg/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
            . No refund or exchange of tickets is allowed once your booking is
            confirmed.
          </li> */}
        </ol>
      </div>
      <div className="consent_button">
        <Button
          primary
          onClick={(e) => {
            props.changeTransactionValues("transactionStatus", 2);
          }}
          disabled={props.transactionDetails.checkconsent ? false : true}
        >
          Proceed to payment
        </Button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data) => dispatch(transActions.fetchTransaction(data)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionCondition);
