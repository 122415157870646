import React, { useEffect, useState } from "react";
import { Shell, Scheduler, RightArrowIcon } from "@StixNext/mojito-ui";
import { Grid, Row } from "@StixNext/mojito-ui";
import { connect } from "react-redux";
import * as actions from "../actions/Venue";
import workshop from "../assets/images/workshop.png";
import radio_web from "../assets/images/radio_web.gif";
import radio_mob from "../assets/images/radio_mob.jpg";
const sortByProperty = (property) => {
  return (a, b) => {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;
    return 0;
  };
};
const InVenueProgrammes = (props) => {
  const { history, setDateSelected, dateSelected } = props;
  let { venueList = [], fetchVenue } = props;
  let slides = [
    { title: " TITLE OF THE SHOW ", url: workshop, buttonText: "Now playing" },
    { title: "TITLE OF THE SHOW 2", url: workshop, buttonText: "Now playing" },
    { title: "TITLE OF THE SHOW 3", url: workshop, buttonText: "Now playing" },
    { title: "TITLE OF THE SHOW 4", url: workshop, buttonText: "Now playing" },
    { title: "TITLE OF THE SHOW 5", url: workshop, buttonText: "Now playing" },
    { title: "title of the show 6", url: workshop, buttonText: "Now playing" },
    { title: "title of the show 7", url: workshop, buttonText: "Now playing" },
    { title: "title of the show 8", url: workshop, buttonText: "Now playing" },
    { title: "title of the show 9", url: workshop, buttonText: "Now playing" },
    { title: "TITLE OF THE SHOW 10", url: workshop, buttonText: "Now playing" },
    { title: "TITLE OF THE SHOW 11", url: workshop, buttonText: "Now playing" },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };
  let defultChannel = [
    { channelName: "The Arts House Chamber", channelId: 1 },
    { channelName: "The Arts House Living Room", channelId: 2 },
    { channelName: "Victoria Theatre", channelId: 3 },
  ];
  const [channel, setChannel] = useState(defultChannel);
  const [dateOptions, setDateOptions] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(defultChannel);
  const [scheduleData, setScheduleData] = useState({ data: venueList });
  useEffect(() => {
    fetchVenue();
  }, []);
  useEffect(() => {
    handleChannelView();
  }, [windowWidth, selectedChannel]);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);
  const handleChannelView = () => {
    if (windowWidth <= 760) {
      let filterChannel = defultChannel.filter(
        (item) => item.channelId == selectedChannel
      );
      let defultValue = defultChannel.length > 0 ? defultChannel[0] : {};
      filterChannel = filterChannel.length == 0 ? [defultValue] : filterChannel;

      setChannel(filterChannel);
    } else {
      setChannel(defultChannel);
    }
  };

  let timeLine = [
    { time: "10:00", endTime: "1100", startTime: "1000" },
    { time: "11:00", endTime: "1200", startTime: "1100" },
    { time: "12:00", endTime: "1300", startTime: "1200" },
    { time: "13:00", endTime: "1400", startTime: "1300" },
    { time: "14:00", endTime: "1500", startTime: "1400" },
    { time: "15:00", endTime: "1600", startTime: "1500" },
    { time: "16:00", endTime: "1700", startTime: "1600" },
    { time: "17:00", endTime: "1800", startTime: "1700" },
    { time: "18:00", endTime: "1900", startTime: "1800" },
    { time: "19:00", endTime: "2000", startTime: "1900" },
    { time: "20:00", endTime: "2100", startTime: "2000" },
    { time: "21:00", endTime: "2200", startTime: "2100" },
    { time: "22:00", endTime: "2300", startTime: "2200" },
    { time: "23:00", endTime: "2400", startTime: "2300" },
  ];

  useEffect(() => {
    let dates = [];
    venueList.map((programmes) => {
      dates.push(programmes.date);
    });
    let uniqueDates = dates.sort().filter((v, i, a) => a.indexOf(v) === i);
    setDateOptions(uniqueDates);
    if (!dateSelected) {
      if (uniqueDates.length > 0) {
        setDateSelected(uniqueDates[0]);
      }
    }

    let data = venueList
      .sort(sortByProperty("startTime"))
      .filter((programme) => programme.date == dateSelected);
    data = data.map((d) => {
      d["buttonEnabled"] = true;
      return d;
    });
    if (data.length == 0) {
      if (uniqueDates.length > 0) {
        setDateSelected(uniqueDates[0]);
      }
    }
    setScheduleData({ data });
  }, [venueList]);
  useEffect(() => {
    let data = venueList.filter((programme) => programme.date == dateSelected);
    data = data.map((d) => {
      d["buttonEnabled"] = true;
      return d;
    });

    setScheduleData({ data });
  }, [dateSelected]);
  const handleClick = (eventId) => {
    let ZoomEventUrl = venueList.find((obj) => obj.id === eventId);
    let { Venue } = ZoomEventUrl;
    if (Venue) {
      window.open(`${Venue}`);
    }
    return eventId;
  };
  return (
    <Shell.Body className="live-bdy">
      <a
        href={`${process.env.REACT_APP_RADIO_BANNER}`}
        target="_blank"
        className="spread_section"
      >
        <div>
          <img src={radio_web} alt="icon" className="mike_img" />
          <img src={radio_mob} alt="icon" className="mike_mob_img" />
        </div>
      </a>
      {/* <Grid>
        <Row>
          <Column size={12}>
            <Carousel
              autoPlay={false}
              interval={5000}
              slidesPresented={windowWidth > 760 ? 3 : 1}
              Indictor={true}
              mobileView={windowWidth <= 760}
              className={"carousel"}
            >
              {slides.map((slide, index) => (
                <Card key={index} className={"card-slides"}>
                  <img src={slide.url} alt="" />
                  <Text as="div" className={"title-tiles"}>
                    {slide.title}
                  </Text>
                  <br />
                  <Text as="div" className={"title-tiles-channel"}>
                    {`Channel ${slide.channelId}`}
                  </Text>
                  <br />

                  <Button
                    className={"now-playing-class"}
                    iconStart={<LiveIcon />}
                  >
                    {slide.buttonText}
                  </Button>
                </Card>
              ))}
            </Carousel>
          </Column>
        </Row>
      </Grid> */}
      <Grid className="live-grid">
        <Row>
          <br />
          <h6
            style={{ paddingTop: 5, margin: "0px 15px 15px 15px" }}
            className="schedule-title"
          >
            SCHEDULE <RightArrowIcon style={{ mariginLeft: 5 }} />
          </h6>
          <div>
            <select
              className={"dropdwon-scheduler"}
              value={dateSelected}
              onChange={(e) => setDateSelected(e.target.value)}
            >
              {dateOptions.map((date) => {
                let event = new Date(date);
                const options = {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                };
                return (
                  <option value={date}>
                    {event.toLocaleDateString(undefined, options)}
                  </option>
                );
              })}
            </select>
            {windowWidth <= 760 && (
              <select
                className={"dropdwon-scheduler"}
                value={selectedChannel}
                onChange={(e) => setSelectedChannel(e.target.value)}
              >
                {defultChannel.map((item, index) => (
                  <option value={item.channelId}>{item.channelName}</option>
                ))}
              </select>
            )}
          </div>
        </Row>

        <Row className="scheduler-class">
          <Scheduler
            scheduleData={scheduleData}
            className={"venue-class"}
            timeLine={timeLine}
            titleKey={"title"}
            defultTextButton={""}
            channel={channel}
            eventOnClick={handleClick}
          ></Scheduler>
        </Row>
      </Grid>
    </Shell.Body>
  );
};
const mapStateToProps = (state) => ({
  venueList: state.Venue.venueList,
  dateSelected: state.Venue.dateSelected,
});

const mapDispatchToProps = (dispatch) => ({
  fetchVenue: () => dispatch(actions.fetchVenue()),
  setDateSelected: (date) => dispatch(actions.setDateSelected(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InVenueProgrammes);
