import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Shell,
  Menu,
  BouncerContextHook,
  BouncerContext,
  CloseWhiteIcon,
  CloseIcon,
  B2CHeader,
  Grid,
  Column,
  Row,
  Button,
  Modal,
  UserIcon,
} from "@StixNext/mojito-ui";
import overviewred from "../../assets/images/icons/overview_red.svg";
import livered from "../../assets/images/icons/live_red.svg";
import upcomingred from "../../assets/images/icons/upcoming_red.svg";
import vodred from "../../assets/images/icons/vod_red.svg";
import watchred from "../../assets/images/icons/watch_red.svg";
import logo from "../../assets/images/header.png";
import logomob from "../../assets/images/header.png";
// import Keycloak from "keycloak-js";
import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import queryString from "query-string";
import main1 from "../../assets/images/stage_sq.jpg";
import { TransactionStatus } from "../BuyProductAndTransaction";
import _ from "lodash";
import TagManager from "react-gtm-module";

function Header(props) {
  const { history, location = {} } = props;
  const { pathname = "/" } = location;
  const [keycloak, setKeycloak] = useState({});
  const [closeModal, setCloseModal] = useState(false);
  const value = BouncerContextHook();
  let onClose = () => {
    props.changeTransactionValues("productPrice", 0);
    props.changeTransactionValues("showDropinComponent", false);
    props.changeTransactionValues("transactionStatus", 1);
    props.changeTransactionValues("checkconsent", false);
    props.changeTransactionValues("isOpen", false);
    props.changeTransactionValues("hideBuyButton", false);
    localStorage.setItem("buttonClicked", false);

    if (localStorage.getItem("grouppasslogin") == "true") {
      localStorage.setItem("grouppasslogin", false);
      localStorage.setItem("grouppass", false);
      localStorage.removeItem("grouppassid");
    }
    if (localStorage.getItem("grouppasscompleted") == "false") {
      props.changeTransactionValues("showDropinComponent", false);
      props.changeTransactionValues("checkconsent", false);
    }
  };
  if (
    Object.keys(value.keycloak).length > 0 &&
    Object.keys(keycloak).length < 1
  ) {
    setKeycloak(value.keycloak);
  } else if (
    Object.keys(value.keycloak).length > 0 &&
    Object.keys(keycloak).length > 0 &&
    !_.isEqual(keycloak, value.keycloak)
  ) {
    setKeycloak(value.keycloak);
  }
  // useEffect(() => {
  //   if (value.sessionRestrictor == true) {
  //     props.changeTransactionValues("isOpen", true);
  //     props.changeTransactionValues("transactionStatus", 10);
  //   }
  // }, [value.sessionRestrictor]);

  useEffect(() => {
    if (keycloak.authenticated) {
      props.changeLoginValues(
        "authenticateduser",
        keycloak.tokenParsed.preferred_username
      );
      props.changeLoginValues("user", {
        ...props.loginDetails.user,
        keycloakAccessToken: keycloak.token,
        firstname: keycloak.tokenParsed.given_name,
        lastname: keycloak.tokenParsed.family_name,
        username: keycloak.tokenParsed.preferred_username,
        email: keycloak.tokenParsed.email,
        userid: keycloak.tokenParsed.sub,
      });
      props.changeLoginValues("accessToken", keycloak.token);
      localStorage.setItem("dch_access_token", keycloak.token);
      props.changeLoginValues("keycloak", keycloak);
      props.changeLoginValues("authenticated", keycloak.authenticated);
      TagManager.dataLayer({
        dataLayer: {
          event: "Login",
          userId: keycloak.tokenParsed.sub,
          firstname: keycloak.tokenParsed.given_name,
          lastname: keycloak.tokenParsed.family_name,
          email: keycloak.tokenParsed.email,
        },
      });
    }
  }, [keycloak]);

  let getDegitalPass = async (e) => {
    setCloseModal(false);
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    setTimeout(() => {
      setCloseModal(false);
      if (!props.loginDetails.authenticated) {
        value.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked")
        );
      }
    }, 500);
  };

  let logoutKeycloack = (e) => {
    e.preventDefault();
    props.loginDetails.keycloak.logout();
  };

  useEffect(() => {
    if (props.loginDetails.authenticated) {
      // if (!props.loginDetails.authToken && props.loginDetails.accessToken) {
      //   props.getAuthToken();
      // }

      if (
        props.loginDetails.user.userid
        // &&
        // props.loginDetails.authToken &&
        // props.loginDetails.accessToken
      ) {
        if (localStorage.getItem("grouppasslogin") == "true") {
          props.grouppassTransaction(
            props.loginDetails.user,
            localStorage.getItem("grouppassid")
          );
        } else {
          props.fetchTransaction(
            props.loginDetails.user,
            localStorage.getItem("buttonClicked")
          );
        }
      }
    } else {
      props.changeTransactionValues("hideBuyButton", false);
    }
  }, [
    props.loginDetails.authenticated,
    props.loginDetails.user.userid,
    // props.loginDetails.authToken,
    // props.loginDetails.accessToken,
  ]);

  useEffect(() => {
    value.transactionSuccessFlag =
      props.transactionDetails.transactionSuccessFlag;
  }, [props.transactionDetails.transactionSuccessFlag]);
  useEffect(() => {
    if (props.loginDetails.tokenExpired == "Token Expired") {
      value.loginKeycloak();
      props.changeLoginValues("tokenExpired", "");
      props.changeLoginValues("logout", true);
    }
    // if (props.loginDetails.logout) {
    //   value.loginKeycloak();
    // }
  }, [props.loginDetails.tokenExpired, props.loginDetails.logout]);

  const handleNavigation = (path) => (e) => {
    e.preventDefault();
    history.push(path);
  };

  useEffect(() => {
    localStorage.setItem("buttonClicked", false);
  }, []);
  return (
    <>
      <B2CHeader>
        <B2CHeader.Banner className="SSO_header">
          <Grid>
            <Row>
              <Column>
                <span className="spantag">
                  <img
                    className="baselogo"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/");
                    }}
                    src={logo}
                    alt="image"
                  />
                  <img
                    className="moblogo"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/");
                    }}
                    src={logomob}
                    alt="image"
                  />
                </span>
              </Column>
            </Row>
          </Grid>
        </B2CHeader.Banner>
        <B2CHeader.PoweredByBanner>
          <B2CHeader.UserBuyPass
            closeModal={closeModal}
            updateCloseModal={setCloseModal}
            className="user_buypass"
          >
            <B2CHeader.PopOver>
              <B2CHeader.PopOverItem>
                {!value.sessionRestrictor &&
                props.loginDetails.authenticated &&
                props.transactionDetails.transactionSuccessFlag ? (
                  <div className="popover_success_passcard">
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        paddingTop: "10px",
                      }}
                    >
                      ACCESS PASS HOLDER
                    </p>
                    <span
                      className="span_mail"
                      style={{ fontSize: "12px", paddingTop: "15px" }}
                    >
                      {props.loginDetails.keycloak.tokenParsed.email}
                    </span>
                  </div>
                ) : (
                  <div className="popover_passcard">
                    <span
                      className="span_mail"
                      style={{
                        paddingTop: "25px",
                        paddingBottom: "15px",
                      }}
                    >
                      You dont have access pass
                    </span>
                    {/* <a
                      href="#"
                      onClick={getDegitalPass}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "none",
                      }}
                    >
                      Click here to buy now
                    </a> */}
                  </div>
                )}
                <hr />
              </B2CHeader.PopOverItem>
              <B2CHeader.PopOverItem>
                <a
                  href="https://ticketing.sistic.com.sg/sistic/patron/management"
                  style={{
                    cursor: "pointer",
                    color: "black",
                    textDecoration: "none",
                  }}
                  rel="noreferrer"
                  target="_blank"
                >
                  My Account
                </a>
              </B2CHeader.PopOverItem>
            </B2CHeader.PopOver>
          </B2CHeader.UserBuyPass>
        </B2CHeader.PoweredByBanner>
        <B2CHeader.Navigation>
          {pathname.includes("/livevideo") ||
          pathname.includes("/voddetail") ||
          pathname.includes("/watch") ? (
            <div></div>
          ) : (
            <Menu
              className="SSO_Menu"
              items={[
                {
                  menuItem: "Overview",
                  navigation: "/overview",
                  onClick: handleNavigation("/overview"),
                  active: pathname === "/overview" || pathname === "/",
                  // tagicon: <img src={overviewred} className="tag_icon" />,
                },
                // {
                //   menuItem: "Schedule",
                //   navigation: "/schedule",
                //   onClick: handleNavigation("/schedule"),
                //   active: pathname === "/schedule",
                // },
                // {
                //   menuItem: "Live Stream",
                //   navigation: "/livestream",
                //   onClick: handleNavigation("/livestream"),
                //   active: pathname === "/livestream",
                //   // tagicon: <img src={livered} className="tag_icon" />,
                // },
                {
                  menuItem: "FAQ",
                  navigation: "/FAQ",
                  onClick: handleNavigation("/FAQ"),
                  active: pathname === "/FAQ",
                  // tagicon: <img src={upcomingred} className="tag_icon" />,
                },
                {
                  menuItem: "Media",
                  navigation: "/media",
                  onClick: handleNavigation("/media"),
                  active: pathname === "/media",
                  // tagicon: <img src={upcomingred} className="tag_icon" />,
                },
                // {
                //   menuItem: "On Demand",
                //   navigation: "/ondemand ",
                //   onClick: handleNavigation("/ondemand"),
                //   active: pathname === "/ondemand",
                //   tagicon: <img src={vodred} className="tag_icon" />,
                // },
                // {
                //   menuItem: "Watch for Free",
                //   navigation: "/watchforfree",
                //   onClick: handleNavigation("/watchforfree"),
                //   active: pathname === "/watchforfree",
                //   tagicon: <img src={watchred} className="tag_icon" />,
                // },
              ]}
            ></Menu>
          )}
        </B2CHeader.Navigation>
      </B2CHeader>
      <TransactionStatus
        isOpen={props.transactionDetails.isOpen}
        onClose={onClose}
        transactionSuccessFlag={props.transactionDetails.transactionSuccessFlag}
        transactionData={props.transactionDetails.transactionData}
        username={props.loginDetails.keycloak}
      />
    </>
    // <Shell.Header
    //   className="SSO_header"
    //   headerItems={{
    //     image: logo,
    //     moblogo: logomob,
    //     powerdBySistic: true,
    //     contactUs: "Contact Us",
    //     contactUsLink:
    //       "https://www.singaporewritersfestival.com/nacswf/nacswf/contact-us.html",
    //     transactionSuccessFlag: props.transactionDetails.transactionSuccessFlag,
    //     getDegitalPass: getDegitalPass,
    //     isloginVisible: true,
    //     loginFunction: (e) => {
    //       localStorage.setItem("buttonClicked", false);
    //       localStorage.setItem("logout", false);
    //       return value.loginKeycloak();
    //     },
    //     keycloak: props.loginDetails.keycloak,
    //     authenticated: props.loginDetails.authenticated,
    //     logoutFunction: (e) => {
    //       localStorage.setItem("logout", true);
    //       return logoutKeycloack(e);
    //     },
    //     handleOnClick: (e) => {
    //       e.preventDefault();
    //       history.push("/");
    //     },
    //     isloginVisible: true,
    //   }}
    // >
    //   {pathname.includes("/livevideo") || pathname.includes("/voddetail") ? (
    //     <div></div>
    //   ) : (
    //     <Menu
    //       className="SSO_Menu"
    //       items={[
    //         {
    //           menuItem: "Overview",
    //           navigation: "/overview",
    //           onClick: handleNavigation("/overview"),
    //           active: pathname === "/overview" || pathname === "/",
    //           tagicon: <img src={overviewred} className="tag_icon" />,
    //         },
    //         // {
    //         //   menuItem: "Schedule",
    //         //   navigation: "/schedule",
    //         //   onClick: handleNavigation("/schedule"),
    //         //   active: pathname === "/schedule",
    //         // },
    //         {
    //           menuItem: "Live Now",
    //           navigation: "/livenow",
    //           onClick: handleNavigation("/livenow"),
    //           active: pathname === "/livenow",
    //           tagicon: <img src={livered} className="tag_icon" />,
    //         },
    //         {
    //           menuItem: "Upcoming",
    //           navigation: "/upcoming",
    //           onClick: handleNavigation("/upcoming"),
    //           active: pathname === "/upcoming",
    //           tagicon: <img src={upcomingred} className="tag_icon" />,
    //         },
    //         {
    //           menuItem: "On Demand",
    //           navigation: "/ondemand ",
    //           onClick: handleNavigation("/ondemand"),
    //           active: pathname === "/ondemand",
    //           tagicon: <img src={vodred} className="tag_icon" />,
    //         },
    //         {
    //           menuItem: "Watch for Free",
    //           navigation: "/watchforfree",
    //           onClick: handleNavigation("/watchforfree"),
    //           active: pathname === "/watchforfree",
    //           tagicon: <img src={watchred} className="tag_icon" />,
    //         },
    //       ]}
    //     ></Menu>
    //   )}
    // </Shell.Header>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  getAccessToken: () => dispatch(actions.getAccessToken()),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  grouppassTransaction: (user, passid) =>
    dispatch(transActions.grouppassTransaction(user, passid)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
