import { getProgrammes } from "./commonapi/mockData";

export const changeProgrammesValues = (field, value) => {
  return {
    type: "UPDATE_PROGRAMMES_VALUE",
    field,
    value,
  };
};

export const fetchProgrammes = () => {
  return (dispatch) => {
    getProgrammes()
      .then((data) => {
        dispatch(changeProgrammesValues("programmesList", data));
      })
      .catch((err) => {});
  };
};

export const fetchProgrammeDetail = (progammeId) => {
  return (dispatch) => {
    getProgrammes()
      .then((data) => {
        let programmeDetail = data.filter(function (item) {
          return item.id === progammeId;
        });
        dispatch(changeProgrammesValues("programme", programmeDetail));
      })
      .catch((err) => {});
  };
};
export const setDateSelected = (date) => {
  return (dispatch) => {
    dispatch(changeProgrammesValues("dateSelected", date));
  };
};
