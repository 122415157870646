import * as i18next from 'react-i18next';
const  useCommonI18n = () => {
  const { t , i18n } = i18next.useTranslation();
  const changeLanguage = lng =>i18n.changeLanguage(lng);
  

  return {
    ...i18n,
    translate:t,
    changeLanguage,
    ...i18next
  }
}
export default useCommonI18n