import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Shell,
  VideoPlayer,
  FeatureArtists,
  Grid,
  Row,
  Column,
  Text,
  Button,
  BouncerContextHook,
  CloseWhiteIcon,
  SisticLivePlayer,
  AddOn,
} from "@StixNext/mojito-ui";
import ReactHtmlParser from "react-html-parser";
import Iframe from "react-iframe";
import Moment from "react-moment";
import Zadie from "../assets/images/Zadie.png";
import Book from "../assets/images/book.png";
import BookStore from "../assets/images/bookstore.jpg";
import Donation from "../assets/images/donation.png";
import Arrow from "../assets/images/leftarrow.png";
import SecondScreen from "../assets/images/second_screen.png";
import ChatIco from "../assets/images/chat.png";
import QAIco from "../assets/images/Q&A.png";
import AddonIco from "../assets/images/icons/star_icon_black.svg";
import { TransactionStatus } from "../components/BuyProductAndTransaction";
import Keycloak from "keycloak-js";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import * as progrmmeActions from "../actions/Programmes";
import * as VideoOnDemand from "../actions/VideoOnDemand";
import history from "../stores/history";
import LiveChat from "./LiveChat";
import Chat from "@StixNext/mojito-ui/dist/Chat/Chat";

function VideoOnDemandDetail(props) {
  const { history, location = {} } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [fullscreenEnable, setFullScreenEnable] = useState(false);
  const [legalAge, setLegalAge] = useState(false);
  const [hideAgeOverlay, setHideAgeOverlay] = useState(false);
  const loginContext = BouncerContextHook();
  // let onClose = () => {
  //   props.changeTransactionValues("productPrice", 0);
  //   props.changeTransactionValues("showDropinComponent", false);
  //   props.changeTransactionValues("transactionStatus", 1);
  //   props.changeTransactionValues("checkconsent", false);
  //   props.changeTransactionValues("isOpen", false);
  //   localStorage.setItem("buttonClicked", false);
  // };
  let getDegitalPass = async (e) => {
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        loginContext.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked")
        );
      }
    }, 1000);
    // props.changeTransactionValues("transactionStatus", 10);
    // props.changeTransactionValues("isOpen", true);
    // localStorage.setItem("buttonClicked", true);
    // localStorage.setItem("logout", false);
    // setTimeout(() => {
    //   if (!props.loginDetails.authenticated) {
    //     loginContext.loginKeycloak();
    //   } else {
    //     props.fetchTransaction(
    //       props.loginDetails.user,
    //       localStorage.getItem("buttonClicked")
    //     );
    //   }
    // }, 1000);
  };
  const filterVideoAssets = (id) => {
    let {
      videoOnDemand = {
        videoOnDemandList: [],
      },
    } = props;
    let data = videoOnDemand.videoOnDemandList.filter(
      (programme) => programme.id == id
    );
    return data;
  };

  useEffect(() => {
    if(props.match.path.includes("vdodetail")) {
      let progammeId = props.match.params.id
      props.fetchVideoOnDemandDetail(progammeId);
      return () => {
        props.changeVideoOnDemandValues("videoOnDemandDetail", []);
      };
    }
  }, []);

  useEffect(() => {
    if (props.match.path.includes("watch")) {
      let passId = props.match.params.id
      const fetchMyApi = async function() {
        if (props.loginDetails.user.userid && passId) {
          let res = await props.fetchVideoOnRedeem(props.loginDetails.user, passId);
          console.log("resultttt", res)
        }
      }
      fetchMyApi();
    }
  }, [props.loginDetails.user]);


  return (
    <>
      {props.videoOnDemand.videoOnDemandDetail.length > 0 &&
        props.videoOnDemand.videoOnDemandDetail.map((value, key) => {
          return (
            <div className="row vodContainer">
              <div className="container">
                <Link
                  to={
                    localStorage.getItem("prevPath")
                      ? localStorage.getItem("prevPath")
                      : "/"
                  }
                  className="title"
                >
                  <img src={Arrow} alt="icon" />
                  <Text as="captionblock" className="titletext">
                    {value.title}
                    {/* <br />
                    <div style={{ fontSize: "13px", fontWeight: "100" }}>
                      <Moment format="LT">{value.startDateTime}</Moment> till{" "}
                      <Moment format="LT">{value.endDateTime}</Moment>
                      {" @ "}
                      <span style={{ fontWeight: "bold" }}> Hall 2</span>
                    </div> */}
                  </Text>
                </Link>
                <div className="wholediv">
                  <div className="videodiv voddiv disablechat">
                    {/* {props.loginDetails.authenticated &&
                      props.transactionDetails.hideBuyButton && ( */}
                    <SisticLivePlayer
                      playerItems={{
                        player: false,
                        startDateTime: props.videoOnDemand.videoOnDemandDetail
                          .length
                          ? props.videoOnDemand.videoOnDemandDetail[0]
                              .startDateTime
                          : "",
                        endDateTime: props.videoOnDemand.videoOnDemandDetail
                          .length
                          ? props.videoOnDemand.videoOnDemandDetail[0]
                              .endDateTime
                          : "",
                        authenticated: props.loginDetails.authenticated,
                        hideBuyButton: props.transactionDetails.hideBuyButton,
                        url: value.VideoAssetURL,
                        type: "mp4",
                        trackingId: `${process.env.REACT_APP_GID}`,
                        // poster: "//vjs.zencdn.net/v/oceans.png",
                        enableChat: props.videoOnDemand.videoOnDemandDetail[0]
                          .ChatFlag
                          ? props.videoOnDemand.videoOnDemandDetail[0].ChatFlag
                          : false,
                        chatContent: (
                          <LiveChat session={props.match.params.id} />
                        ),
                      }}
                    />
                  </div>
                  {/* {props.videoOnDemand.videoOnDemandDetail[0].addOn && (
                    <div className="chatsection">
                      <div
                        style={{
                          height: "477px",
                          background: "white",
                        }}
                      >
                        <div className="chatQAMenu">
                          <div className="chatMenuTitle">
                            <span style={{ cursor: "pointer" }}>
                              <span className="chatMenuTitleSpan chatQAMenuActive">
                                Add-Ons
                              </span>
                              <img
                                className="chatMenuTitleImage chatMenuActive"
                                src={AddonIco}
                              />
                            </span>
                          </div>
                        </div>
                        <span className="menuborder"></span>
                        <AddOn
                          className="addondiv"
                          items={
                            props.videoOnDemand.videoOnDemandDetail.length
                              ? props.videoOnDemand.videoOnDemandDetail[0].addOn
                              : ""
                          }
                        />
                      </div>
                    </div>
                  )} */}
                  {/* {props.loginDetails.authenticated === null ||
                  props.loginDetails.authenticated === false ||
                  props.transactionDetails.hideBuyButton === false ? (
                    <div
                      className="mobchatalt"
                      style={{
                        height: "420px",
                        background: "white",
                      }}
                    ></div>
                  ) : (
                    ""
                  )} */}
                  {/* {props.loginDetails.authenticated === null ||
                  props.loginDetails.authenticated === false ||
                  props.transactionDetails.hideBuyButton === false ? (
                    <div className="wrap_div">
                      <Text as="h4" className="wrap_title">
                        This video is only available upon login with your
                        Digital Concert Hall Pass
                      </Text>
                      <div className="pass_div">
                        <div>
                          <Text as="h6" className="wrap_pass">
                            Monthly subscription of $XX to watch all programmes
                          </Text>
                          <Button
                            primary
                            className="video_btn"
                            onClick={(e) => {
                              getDegitalPass(e);
                            }}
                          >
                            Subscribe Now
                          </Button>
                        </div>
                        <div>
                          <Text as="h6" className="wrap_pass">
                            Buy this programme for 48 hours
                          </Text>
                          <Button
                            primary
                            className="video_btn"
                            onClick={(e) => {
                              props.changeTransactionValues(
                                "transactionStatus",
                                8
                              );
                              getDegitalPass(e);
                            }}
                          >
                            Buy Now
                          </Button>
                        </div>
                      </div>
                      {props.loginDetails.authenticated === null ||
                      props.loginDetails.authenticated === false ? (
                        <div>
                          <Text as="h6" className="wrap_login">
                            Already Have a Digital Concert Hall Pass?
                          </Text>
                          <Button
                            primary
                            className="video_btn"
                            onClick={(e) => {
                              localStorage.setItem("logout", false);
                              loginContext.loginKeycloak();
                            }}
                          >
                            Click here to login
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )} */}
                  {/* <LiveChat session={props.match.params.id} /> */}
                  {/* {props.loginDetails.authenticated &&
                    props.transactionDetails.hideBuyButton && ( */}
                  <>
                    {/* {!hideAgeOverlay ? (
                      legalAge ? (
                        <div className="wrap_explore_div">
                          <Text as="h4" className="explore_title">
                            You're not at the legal age to consume the content
                          </Text>
                          <div className="pass_div">
                            <Button
                              primary
                              className="age_button"
                              onClick={() => {
                                history.push("/overview");
                              }}
                            >
                              Explore other content
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="wrap_age_div">
                          <Text as="h4" className="age_title">
                            This content is rated NC16
                            <br />
                            (Violence and coarse language)
                          </Text>
                          <div className="pass_div">
                            <div>
                              <Text as="h6" className="wrap_age">
                                Are you over the age of 16?
                              </Text>
                              <div className="allow_section">
                                <Button
                                  primary
                                  className="age_button"
                                  onClick={() => {
                                    setHideAgeOverlay(true);
                                  }}
                                >
                                  Yes, let me in
                                </Button>
                                <Button
                                  primary
                                  className="age_grey_btn"
                                  onClick={() => {
                                    setLegalAge(true);
                                  }}
                                >
                                  No, I'm not
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      ""
                    )} */}
                  </>
                  {/* )} */}
                </div>
              </div>
              <div className="synopiscontainer">
                <div className="synopisdiv">
                  <div
                    className={`synopissection ${
                      value.moderator ? "" : "disablemoderator"
                    }`}
                  >
                    <Text as="h6" className="synopsis">
                      Short Introduction
                    </Text>
                    <Text as="p">
                      {value.writeUp &&
                        ReactHtmlParser(
                          value.writeUp.replace(/(?:\r\n|\r|\n)/g, "<br />")
                        )}
                    </Text>
                    {/* <div className="bookwebsection">
                      <div className="booksection">
                        {value.donationLink && (
                          <a
                            href={value.donationLink}
                            target="_blank"
                            style={{
                              display: "flex",
                              textDecoration: "none",
                            }}
                          >
                            <img
                              src={Donation}
                              alt="icon"
                              style={{
                                width: "150px",
                                height: "150px",
                                borderRadius: "50%",
                              }}
                            />
                            <Text as="p" className="booktext">
                              Click here to donate <br />
                              to our giving.sg
                            </Text>
                          </a>
                        )}
                      </div>
                    </div> */}
                  </div>
                  {value.featuredPresenters && (
                    <div className="chatsection">
                      <div className="videoDetailFeature">
                        <Text as="h4">
                          <b>FEATURE COMPOSERS</b>
                        </Text>
                        <Text as="p">
                          {value.featuredPresenters
                            ? ReactHtmlParser(
                                value.featuredPresenters.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br />"
                                )
                              )
                            : null}
                        </Text>
                      </div>

                      {/* <div className="videoDetailFeature">
                      <Text as="h6">
                        <b>Moderator</b>
                      </Text>
                      <Text as="p">
                        {value.moderator
                          ? ReactHtmlParser(
                              value.moderator.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              )
                            )
                          : null}
                      </Text>
                    </div> */}
                    </div>
                  )}
                </div>
              </div>
              <div className="bookcontainer">
                <div className="bookdiv">
                  <div className="bookmobsection">
                    {value.bookstoreLink && (
                      <a
                        href={`https://${value.bookstoreLink}`}
                        target="_blank"
                        style={{
                          display: "flex",
                          paddingLeft: "15px",
                          paddingBottom: "32px",
                          textDecoration: "none",
                        }}
                      >
                        <img
                          src={BookStore}
                          alt="icon"
                          style={{ width: "110px", height: "170px" }}
                        />
                        <Text as="p" className="booktext">
                          Visit swfbooks.com
                        </Text>
                      </a>
                    )}
                    {value.donationLink && (
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "15px",
                          paddingBottom: "32px",
                        }}
                      >
                        <img
                          src={Donation}
                          alt="icon"
                          style={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                          }}
                        />
                        <Text as="p" className="booktext">
                          Click here to donate <br />
                          to our giving.sg
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      {/* <TransactionStatus
        isOpen={props.transactionDetails.isOpen}
        onClose={onClose}
        transactionSuccessFlag={props.transactionDetails.transactionSuccessFlag}
        transactionData={props.transactionDetails.transactionData}
        username={props.loginDetails.keycloak}
      /> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    programmeDetails: state.Programmes,
    videoOnDemand: state.VideoOnDemand,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProgrammesValues: (f, e) =>
    dispatch(progrmmeActions.changeProgrammesValues(f, e)),
  changeVideoOnDemandValues: (f, e) =>
    dispatch(VideoOnDemand.changeVideoOnDemandValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
  fetchProgrammeDetail: (id) =>
    dispatch(progrmmeActions.fetchProgrammeDetail(id)),
  fetchVideoOnDemandDetail: (progammeId) =>
    dispatch(VideoOnDemand.fetchVideoOnDemandDetail(progammeId)),
  fetchVideoOnRedeem: (user, programmeId) =>
    dispatch(VideoOnDemand.fetchVideoOnRedeem(user, programmeId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoOnDemandDetail);
