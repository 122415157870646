import { sessionGet } from "./TokenValidation";

export const headers = () => {
  var token = sessionGet("dch_access_token");
  if (token === "Token Expired") {
    return token;
  } else {
    return {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }
};
