import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Text, Button, DropInPayment } from "@StixNext/mojito-ui";

import main from "../../assets/images/main.jpg";
import loading from "../../assets/images/loading.gif";

import { theme } from "./../../Theme";
import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";

function SessionRestriction(props) {
  return (
    <div>
      <div>
        <Row>
          <Column>
            <Text as="h4" className="oops_txt">
              OOPS!
            </Text>
            <br />
            <Text as="h5" className="stream_txt">
              It looks like you've reached your device limit for streaming.
            </Text>
            <br />
            <Text as="h5" className="stream_txt">
              You can stream up to 1 device only.
            </Text>
            <br />
            <Text as="h5" className="stream_txt">
              Please logout from another device and
            </Text>
            <br />
            <Text as="h4" className="stream_txt">
              re-login here to continue watching.
            </Text>
          </Column>
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data) => dispatch(transActions.fetchTransaction(data)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user, product) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user, product)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionRestriction);
