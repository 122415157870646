import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Text, Button, DropInPayment } from "@StixNext/mojito-ui";

import main from "../../assets/images/main.jpg";
import loading from "../../assets/images/loading.gif";

import { theme } from "./../../Theme";
import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";

function TechnicalError(props) {
  return (
    <div>
      <div>
        <Row>
          <Column>
            <Text as="h4" className="oopsland_txt">
              JJ LIN「SANCTUARY FINALE」VIRTUAL CONCERT <br />
              REFUNDS, SINGLE VIEW BROADCAST & INCIDENT UPDATE
            </Text>
            <br />
            <Text as="h5" className="stream_txt">
              ​With reference to the incident on 10 July 2021 when SISTIC was
              unable to resolve streaming issues during the live broadcast of JJ
              Lin「Sanctuary Finale」Virtual Concert.
              <br />
              <br />
              We acknowledge that we have let down JJ Lin, his fans,
              JFJ Productions, Unusual Entertainment, Isotope
              Productions, Partners and all crew members who had worked so hard
              to make this highly anticipated show a reality.
              <br />
              <br />
              As a result, SISTIC will offer refunds to all affected patrons.
              The concert will also be made available to ticket holders who wish
              to watch the re-stream.
            </Text>
            <br />
            <Text as="h4" className="faq_watch_title">
              <strong>{"Redemption of Virtual Concert "}</strong>
              <br />
            </Text>
            <br />
            <Text as="h5" className="faq_watch_para">
              {
                "All ticket holders will receive a unique link via email to access the full  ​JJ Lin「Sanctuary Finale」Virtual Concert at a time of their convenience between 19 July 2021 1000h (GMT +8) to 2 August 2021 2359h (GMT +8)"
              }
              <br />
              <br />
              If you did not receive the email within the next 24 hours, please
              get in touch with the SISTIC Customer Service team at +65 6348
              5555 or{" "}
              <a href="mailto:feedback@sistic.com.sg." target="_blank">
                feedback@sistic.com.sg.{" "}
              </a>
            </Text>
            <Text as="h4" className="faq_watch_title">
              {"i) To Watch The Virtual Concert "}
              <br />
            </Text>
            <br />
            <Text as="h5" className="faq_watch_para">
              <ol className="indentation">
                <li>
                  Please log in using your SISTIC account with your assigned
                  link.{" "}
                </li>
                <li>
                  Please note that after you log in, you must click "I Agree" to
                  access the stream. The ticket will be deemed as utilised only
                  after you click the "I Agree" button.{" "}
                </li>
                <li>
                  After log in, once the link is activated, you must complete
                  the viewing in one session within 125 minutes.{" "}
                </li>
                <li>Do not refresh the browser during this time. </li>
                <li>
                  DO NOT switch the browser or swap devices after log in.{" "}
                </li>
                <li>
                  No playbacks, scrubbing or pauses are allowed. This is to
                  ensure compliance to copyright requirements.{" "}
                </li>
                <li>
                  Please proceed to log in ONLY when you are ready to view, as
                  you can only view the concert once. You may choose to view the
                  full concert at a time of your convenience before 2 August
                  2021, 2359h (GMT +8).{" "}
                </li>
              </ol>
            </Text>
            <Text as="h4" className="faq_watch_title">
              {"ii) If Unable To Watch The Virtual Concert "}
              <br />
            </Text>
            <br />
            <Text as="h5" className="faq_watch_para">
              {
                "Ticket holders who are not able to watch the virtual concert during the stated period may share the link with a friend. Reselling your ticket is strictly prohibited and your link would be forfeited should you do so.  "
              }
              <br />
              <br />
              {
                "If you encounter problems with your link while watching, please contact our customer service for assistance.  "
              }
              <br />
              <br />
              For full FAQs, please visit this{" "}
              <a href="https://jjlin0606sf-live.sistic.com/FAQ">
                link.{" "}
              </a>
            </Text>
            <Text as="h4" className="faq_watch_title">
              <strong>{"Refund Details "}</strong>
              <br />
            </Text>
            <br />
            <Text as="h5" className="faq_watch_para">
              All ticket holders will receive an automatic refund from SISTIC,
              made to the mode of payment used during purchase before 10 August
              2021. No further action is required from the ticket holder. Ticket
              holders who have terminated their original payment modes may
              contact us at{" "}
              <a href="mailto:feedback@sistic.com.sg." target="_blank">
                feedback@sistic.com.sg.{" "}
              </a>{" "}
              for assistance.
              <br />
              <br />
              {
                "Kindly note that the refund amount includes the access pass link, Finale Passport, Luminous Wristband and Booking Fee (S$41 for the Time Traveller ticket bundle and S$52 for all other ticket bundles). There shall be no refunds for the Sanctuary Tapestry Fabric Poster, Limited Edition Sanctuary Pictorial Box Set and Special Edition Sanctuary Eco Tote Bag which have been fulfilled.  "
              }
              <br />
              <br />
              For full FAQs, please visit this{" "}
              <a href="https://jjlin0606sf-live.sistic.com/FAQ">
                link.{" "}
              </a>
            </Text>
            <Text as="h4" className="faq_watch_title">
              <strong>{"Incident Update "}</strong>
              <br />
            </Text>
            <br />
            <Text as="h5" className="faq_watch_para">
              {
                "We have conducted full investigations, and have identified that the incident was caused by the failure of the primary and backup ingestion servers of our Content Delivery Network during the live stream."
              }
              <br />
              <br />
              {
                "SISTIC has doubled the capacity of our infrastructure and conducted multiple stress and load tests to cater to the anticipated traffic during the event day. It was unfortunate that despite numerous tests before the event, the live stream was interrupted by a technical fault. "
              }
              <br />
              <br />
              {
                "To prevent the same issue from happening and to ensure you have a good viewing experience, we have improved the video delivery with a better Content Delivery Network.  "
              }
              <br />
              <br />
              For full FAQs, please visit this{" "}
              <a href="https://jjlin0606sf-live.sistic.com/FAQ">
                link.{" "}
              </a>
              <br />
              <br />
              We are thankful for your patience. If you have further queries,
              please contact the SISTIC Customer Service team at +65 6348 5555
              or{" "}
              <a href="mailto:feedback@sistic.com.sg." target="_blank">
                feedback@sistic.com.sg.{" "}
              </a>
            </Text>

            <Text as="h4" className="oopsland_txt">
              JJ 林俊傑《聖所 FINALE》終點站 線上演唱
            </Text>
            <br />
            <Text as="h5" className="stream_txt">
              對於 2021 年 7 月 10 日 在 JJ 林俊傑《聖所 FINALE》終點站
              線上演唱發生的技術問題，我們深感遺憾，也知道辜負了 JJ
              林俊傑、他的歌迷、JFJ Productions、Unusual Entertainment、 Isotope
              Productions
              以及線上演唱會的製作團隊，合作夥伴和所有如此努力現實這個備受期待的線上演唱的臺前幕後工作人員。​
              <br />
              <br />
              ​為了表達我們的歉意, SISTIC
              將向所有受影響的顧客進行退款，並安排顧客重新觀看完整的 JJ
              林俊傑《聖所 FINALE》終點站線上演唱會。
            </Text>
            <br />
            <Text as="h4" className="faq_watch_title">
              <strong>{"[ 演唱會觀看指示 ] "}</strong>
              <br />
            </Text>
            <br />
            <Text as="h5" className="faq_watch_para">
              {
                "所有持票人將會收到電子郵件，電郵內含一個獨特鏈接，此鏈接能讓您在 2021年7月19日早上10:00（GMT +8）至 2021年 8月2日晚上11:59（GMT +8）之間一次性觀看線上演唱會轉播。​ "
              }
              <br />
              <br />
              如果您在24小時內未收到 SISTIC 客服發的電子郵件，請通過
              <a href="mailto:feedback@sistic.com.sg." target="_blank">
                feedback@sistic.com.sg.{" "}
              </a>
              與我們聯繫。​
            </Text>
            <Text as="h4" className="faq_watch_title">
              {"​觀看期間 "}
              <br />
            </Text>
            <br />
            <Text as="h5" className="faq_watch_para">
              <ol className="indentation">
                <li>
                  一旦您點擊了您的獨特鏈接登入觀看平台後，您必须点击 “I
                  Agree"（我同意），鏈接就算是採用了。​
                </li>
                <li>獨特鏈接僅提供一次性的觀看，敬請留意。</li>
                <li>登入后觀看鏈接有效期為125分鐘。請不要刷新瀏覽器。</li>
                <li>
                  觀看期間，由於版權合規，您將無法刷新、轉換瀏覽器或更換您的播放儀器。您也不能回放、拖動或暫停播放。{" "}
                </li>
                <li>
                  因此，請確認您已準備好可以觀看整場演出後才用您的獨特鏈接登入播放平台。一人獨特鏈接只允許一次觀看權。
                </li>
                <li>
                  您可以在有效期間的任何時段觀看，但請務必於 2021
                  年8月2日，晚上11:59 (GMT +8) 前把演出看完。
                </li>
              </ol>
            </Text>
            <Text as="h4" className="faq_watch_title">
              {"​無法觀看 "}
              <br />
            </Text>
            <br />
            <Text as="h5" className="faq_watch_para">
              <ol className="indentation">
                <li>
                  無法在指定日期觀看演出的持票人可以把鏈接分享給家人或朋友觀看。轉賣您的獨特鏈接是嚴厲禁止的。若被發現，您的獨特鏈接可能會將被取消。​{" "}
                </li>
              </ol>
              {"如您有疑問，請與 SISTIC 客戶服務聯繫。 "}
              <br />
              <br />
              "點擊此處查看：
              <a href="https://jjlin0606sf-live.sistic.com/FAQ">
                常見問題{" "}
              </a>
            </Text>
            <Text as="h4" className="faq_watch_title">
              <strong>{"[ 退款資訊 ]​ "}</strong>
              <br />
            </Text>
            <br />
            <Text as="h5" className="faq_watch_para">
              {
                "購票者將在2021年8月10日前收到退款。金額將退回您購票時使用的付款方式，而不是您的 SISTIC 帳戶。 "
              }
              <br />
              <br />
              {
                "退款金額包括電子門票 、終點站時空通行證、互動光芒手環及手續費（標準時空旅人 - S$41 光芒時空旅人 / 進階時空旅人 / 頂級時空旅人 - S$52）。退款將不包含已郵寄出的官方物品 (限量套裝 - 聖所掛上掛布海報, 聖所寫真卡片組, 聖所裝進帆布袋)。"
              }
              <br />
              <br />
              ​如果您的信用卡或支付模式已被取消，請電郵{" "}
              <a href="mailto:feedback@sistic.com.sg." target="_blank">
                feedback@sistic.com.sg.{" "}
              </a>{" "}
              或撥打 +65 6348 5555 與我們的客服聯繫。
            </Text>
            <Text as="h4" className="faq_watch_title">
              <strong>{"[ 調查事件更新 ]  "}</strong>
              <br />
            </Text>
            <br />
            <Text as="h5" className="faq_watch_para">
              {
                "​儘管我們在正式活動日之前加強了基礎設施容量以滿足活動當天兩倍的流量，並進行多次壓力和負載測試和準備，直播還是因技術故障而造成您的困擾。"
              }
              <br />
              <br />
              {
                "​我們進行了全面調查後，確認該事件是由我們的內容傳輸網路故障造成的。主要和備份攝入伺服器在直播期間同時出現故障，導致直播中斷的情況。 "
              }
              <br />
              <br />
              {
                "以防止類似故障再發生，並確保您在轉播期間有良好的觀看體驗，我們已經提升了內容傳輸網路設施。"
              }
              <br />
              <br />
              我們感謝您的耐心等待。如果您有任何問題，請電郵至{" "}
              <a href="mailto:feedback@sistic.com.sg." target="_blank">
                feedback@sistic.com.sg.{" "}
              </a>{" "}
              或撥電 +65 6348 5555 聯絡 SISTIC 客戶服務。 ​
              <br />
              <br />
              ​謝謝。
            </Text>
          </Column>
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data) => dispatch(transActions.fetchTransaction(data)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user, product) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user, product)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TechnicalError);
