import { api } from "./commonapi/apiPaths";
import { sessionSet, ifTokenExpire } from "./commonapi/TokenValidation";
import { headers } from "./commonapi/Headers";

export const changeLoginValues = (field, value) => {
  return {
    type: "UPDATE_LOGIN_VALUE",
    field,
    value,
  };
};

export const getAuthToken = () => {
  var header = headers();
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.accessToken, {
        method: "GET",
        headers: header,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(changeLoginValues("authToken", data.clientToken));
          }
        })
        .catch((err) => {});
    }
  };
};

export const getAccessToken = () => {
  let username = "apitestuser";
  let password = "P@ssw0rd$1";
  return (dispatch) => {
    return fetch(api.authorizationToken, {
      method: "POST",
      headers: {
        Authorization: "Basic " + window.btoa(username + ":" + password),
      },
    })
      .then((res) => {
        return res.json();
      })
      .then(async (data) => {
        await sessionSet("dch_access_token", data.access_token, 5);

        // window.localStorage.setItem("dch_access_token", data.access_token);
        dispatch(changeLoginValues("accessToken", data.access_token));
      })
      .catch((err) => {});
  };
};
