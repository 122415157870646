import { theme } from "@StixNext/mojito-ui";

theme.mojito.colors.primaryBlue = "#191a1a";
theme.mojito.colors.primaryHover = "#191a1a";
theme.mojito.colors.primaryActive = "#191a1a";
theme.mojito.colors.primaryDisabled = "#8c8c8c";
theme.mojito.colors.checkBoxBorderColor = "#02c09e;";
theme.mojito.colors.checkBoxBackgroundColor = "#02c09e;";
theme.mojito.colors.disabledText = "#FFFFFF";
theme.mojito.colors.primaryMenuHover = "#ff0200";
theme.mojito.buttonBorder.buttonBorderRadius = "0px";
theme.mojito.opacities.checkboxOpacity = "0";
theme.mojito.carouselThemeColors.ActiveIndices = "#ff020c";
theme.mojito.colors.primaryModalBackground = "rgba(0, 0, 0, 0.81)";
export { theme };
