import React from "react";
import {
  Reaction,
  HahaIcon,
  LikeIcon,
  BouncerContextHook,
} from "@StixNext/mojito-ui";

import { useGlobalWorker } from "../worker";

function LiveReaction(props) {
  const { session } = props;
  const { keycloak } = BouncerContextHook();
  const { idTokenParsed } = keycloak;
  const { preferred_username: name = "", sub: uid = "" } = idTokenParsed;

  const { reaction_worker, reactions, sendReaction } = useGlobalWorker(session);

  const emojiList = {
    1: <img alt="haha-emoji" height="25" width="25" src={"/Hahah.png"}></img>,
    2: <img alt="heart-emoji" height="25" width="25" src={"/heart.png"}></img>,
    3: <img alt="like-emoji" height="25" width="25" src={"/like.png"}></img>,
  };


  React.useEffect(() => {
    props.highlight || props.stopChat ? reaction_worker.stopSessionReaction()
      : reaction_worker.startSessionReaction(session, uid);
  }, [session, uid, reaction_worker, props.highlight, props.shopChat]);

  return (
    <Reaction
      highlight={props.highlight}
      sendReaction={(e) => {
        sendReaction({ name, session, text: e, uid });
      }}
      messageList={reactions}
      sendReactionDelay={500}
      emojiList={emojiList}
    />
  );
}

export default LiveReaction;
