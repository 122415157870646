import { api } from "./commonapi/apiPaths";
import { headers } from "./commonapi/Headers";
import { ifTokenExpire } from "./commonapi/TokenValidation";
import { changeLoginValues } from "./Login";

export const changeProductValues = (field, value) => {
  return {
    type: "UPDATE_PRODUCT_VALUE",
    field,
    value,
  };
};

export const fetchProducts = () => {
  var header = headers();
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.products, {
        headers: header,
        method: "POST",
        body: JSON.stringify({
          clientid: process.env.REACT_APP_CLIENTID,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(changeProductValues("productList", data.product));
            dispatch(changeProductValues("productLoading", false));
          }
        })
        .catch((err) => {});
    }
  };
};
