import { getVideoOnDemand } from "./commonapi/mockData";
import { headers } from "./commonapi/Headers";
import { api } from "./commonapi/apiPaths";

export const changeVideoOnDemandValues = (field, value) => {
  return {
    type: "UPDATE_VIDEOONDEMAND_VALUE",
    field,
    value,
  };
};

export const fetchVideoOnDemand = () => {
  return (dispatch) => {
    getVideoOnDemand()
      .then((data) => {
        dispatch(changeVideoOnDemandValues("videoOnDemandList", data));
      })
      .catch((err) => {});
  };
};

export const fetchVideoOnDemandDetail = (progammeId) => {
  return (dispatch) => {
    getVideoOnDemand()
      .then((data) => {
        let programmeDetail = data.filter(function (item) {
          return item.id === progammeId;
        });
        dispatch(
          changeVideoOnDemandValues("videoOnDemandDetail", programmeDetail)
        );
      })
      .catch((err) => {});
  };
};

export const fetchVideoOnRedeem = (user, session, passid) => {
  let header = headers();
  const postPayload = {};
  postPayload.code = passid;
  postPayload.clientid = process.env.REACT_APP_CLIENTID;
  postPayload.user = {
    id: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  postPayload.session = session

  return (dispatch) => {
    if (header === "Token Expired") {
      //return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.redeemVOD, {
        method: "POST",
        headers: header,
        body: JSON.stringify(postPayload),
      })
        .then((res) => res.json())
        .then((data) => data)
        .catch((err) => err);
    }
  };
};

export const fetchRedeemStatus = (user, session, passid) => {
  let header = headers();
  const postPayload = {};
  postPayload.code = passid;
  postPayload.clientid = process.env.REACT_APP_CLIENTID;
  postPayload.user = {
    id: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  postPayload.session = session

  return (dispatch) => {
    if (header === "Token Expired") {
      //return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.statusVOD, {
        method: "POST",
        headers: header,
        body: JSON.stringify(postPayload),
      })
        .then((res) => res.json())
        .then((data) => data)
        .catch((err) => err);
    }
  };
};
