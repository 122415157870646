import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Text, Button, DropInPayment } from "@StixNext/mojito-ui";

import main from "../../assets/images/main.jpg";
import loading from "../../assets/images/loading.gif";

import paypal_img from "../../assets/images/paypal.png";
import cards_img from "../../assets/images/cards.png";

import { theme } from "./../../Theme";
import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";

// const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

function Transaction(props) {
  const [checkRadio, setCheckRadio] = useState(1);
  const [paid, setPaid] = React.useState(false);
  const [error, setError] = React.useState(null);

  let FUNDING_SOURCES = [
    window.paypal.FUNDING.PAYPAL,
    window.paypal.FUNDING.CARD,
  ];
  useEffect(() => {
    //hide card first
    document.querySelector("#card-button").classList.add("hidden");
    document.querySelector("#paypal-button").classList.add("hidden");
    document.querySelector("#card-loader").classList.remove("hidden");
  }, []);

  useEffect(() => {
    loadPaymentMethods();
  }, [props.loginDetails.authToken]);

  const loadPaymentMethods = () => {
    window.braintree.client.create(
      {
        authorization: props.loginDetails.authToken,
      },
      function (clientErr, clientInstance) {
        if (clientErr) {
          console.error("Error creating client:", clientErr);
          return;
        }

        // Create a PayPal Checkout component.
        window.braintree.paypalCheckout.create(
          {
            client: clientInstance,
          },
          function (paypalCheckoutErr, paypalCheckoutInstance) {
            if (paypalCheckoutErr) {
              console.error(
                "Error creating PayPal Checkout:",
                paypalCheckoutErr
              );
              return;
            }
            paypalCheckoutInstance.loadPayPalSDK(
              {
                vault: false,
                currency: "SGD",
                intent: "capture",
              },
              function () {
                FUNDING_SOURCES.forEach(function (fundingSource) {
                  let btn = window.paypal.Buttons({
                    style: {
                      layout: "vertical",
                      shape: "rect",
                      label: "checkout",
                      size: "responsive",
                    },
                    fundingSource: fundingSource,
                    createOrder: function () {
                      return paypalCheckoutInstance.createPayment({
                        flow: "checkout",
                        amount: props.transactionDetails.productPrice,
                        intent: "capture",
                        currency: "SGD",
                        enableShippingAddress: true,
                        displayName: "Sistic",
                      });
                    },

                    onApprove: function (data, actions) {
                      // some logic here before tokenization happens below
                      return paypalCheckoutInstance
                        .tokenizePayment(data)
                        .then(function (payload) {
                          //payload.details.shippingAddress returns the shipping address
                          setPaid(true);
                          hanldePayment(payload);
                        });
                    },
                    onCancel: function () {
                      alert("Transaction cancelled.");
                    },

                    onError: function (err) {
                      alert("An error has occured. Please check console.");
                    },
                  });
                  if (btn.isEligible()) {
                    btn.render(`#${fundingSource}-button`);
                    setTimeout(() => {
                      document
                        .querySelector(`#${fundingSource}-loader`)
                        .classList.add("hidden");
                      document
                        .querySelector("#card-button")
                        .classList.remove("hidden");
                    }, 1000);
                  }
                });
              }
            );
          }
        );
      }
    );
  };

  useEffect(() => {
    if (props.transactionDetails.transactionSuccessFlag) {
      setPaid(false);
    }
  }, [props.transactionDetails.transactionSuccessFlag]);

  useEffect(() => {
    document
      .querySelectorAll("input[name=payment-option]")
      .forEach(function (el) {
        el.addEventListener("change", function (event) {
          // If PayPal is selected, show the PayPal button
          if (event.target.value === "paypal") {
            document.querySelector("#paypal-loader").classList.remove("hidden");
            document.querySelector("#card-button").classList.add("hidden");
            // document.querySelector("#paypal-button").classList.remove("hidden");
            setTimeout(() => {
              document.querySelector("#paypal-loader").classList.add("hidden");
              document
                .querySelector("#paypal-button")
                .classList.remove("hidden");
            }, 1000);
          }

          // If Card is selected, show the card button
          if (event.target.value === "card") {
            document.querySelector("#card-loader").classList.remove("hidden");
            // document.querySelector("#card-button").classList.remove("hidden");
            document.querySelector("#paypal-button").classList.add("hidden");
            setTimeout(() => {
              document.querySelector("#card-loader").classList.add("hidden");
              document.querySelector("#card-button").classList.remove("hidden");
            }, 1000);
          }
        });
      });
  });

  const hanldePayment = (cardPayload) => {
    props.checkoutTransaction(
      cardPayload,
      props.loginDetails.user,
      props.productDetails.selectedProduct
    );
  };

  return (
    <div className={paid ? "loader_show" : ""}>
      <div className={paid ? "loader-pro-show" : "loader-pro"}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <img
            className="img-fluid"
            style={{ width: "190px", height: "auto" }}
            src={loading}
            alt="Avatar"
          />
        </div>
      </div>
      <div>
        <Row>
          <Column xl={12} xs={12}>
            <Text as="h4" className="transaction_header">
              You Are Buying JJ LIN [SANCTUARY FINALE] Virtual Concert - $
              {Number(props.transactionDetails.productPrice).toFixed(2)}
              <br />
              您正在購買林俊傑「聖所FINALE」終點站線上演唱會門票 - $
              {Number(props.transactionDetails.productPrice).toFixed(2)}
            </Text>
          </Column>
        </Row>
        <Row>
          <Column>
            <Text as="p" className="trans_merchan">
              *Merchandise will be sent to the indicated shipping address. Due
              to the pandemic, delivery services to many destinations may be
              delayed or failed, subject to the local laws, regulations and
              policies. By purchasing, you acknowledge and agree that no
              guarantee of delivery and no compensation will be provided under
              the aforementioned conditions.
              <br />
              <br />
              *基於疫情，寄送服務可能會因為寄送目的地的當地法律、法規和政策而遲誤、無法送到。您瞭解並同意，任何保證和賠償均不會適用於上述情形。
            </Text>
          </Column>
        </Row>
      </div>
      <>
        <div
          className={
            paid ? "buttons-div-none" : "flex flex-col justify-end buttons-div"
          }
          style={{ paddingTop: "20px" }}
        >
          <div
            className="flex items-center text-gray-500 relative"
            style={{ width: "200px" }}
          >
            <label className="mt-5 flex items-center paypal-label">
              <input
                className="mr-4"
                type="radio"
                name="payment-option"
                defaultValue="card"
                defaultChecked
              />
              <img
                className="w-4/5"
                src={cards_img}
                alt="Accepting Visa, Mastercard, Discover and American Express"
              />
            </label>
          </div>
          <div
            className="hidden"
            id="card-loader"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              className="img-fluid"
              style={{ width: "190px", height: "auto" }}
              src={loading}
              alt="Avatar"
            />
          </div>
          <div className="mt-2">
            <div className id="card-button" />
          </div>
          <div
            className="flex items-center text-white relative"
            style={{ width: "200px" }}
          >
            <label className="flex items-center paypal-label">
              <input
                className="mr-4"
                type="radio"
                name="payment-option"
                defaultValue="paypal"
              />
              <img src={paypal_img} alt="Pay with Paypal" />
            </label>
          </div>
          <div
            className="hidden"
            id="paypal-loader"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              className="img-fluid"
              style={{ width: "190px", height: "auto" }}
              src={loading}
              alt="Avatar"
            />
          </div>
          <div className="mt-2">
            <div className id="paypal-button" />
          </div>
        </div>
      </>

      {/* <div ref={paypalRef}></div> */}
      {/* <div
        className="loader-pro"
        style={{
          width: "100%",
          display: props.transactionDetails.loader,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <img
            className="img-fluid"
            style={{ width: "190px", height: "auto" }}
            src={loading}
            alt="Avatar"
          />
        </div>
      </div>
      <div
        className="dropPayment"
        style={{
          width: "100%",
          display: props.transactionDetails.paymentLoader,
        }}
      ></div> */}
      <div className={"drop-in-payment"}>
        {props.transactionDetails.showDropinComponent ? (
          <>
            {/* <div className="flex mb-3">
              <p className="py-1 px-1 font-semibold">Amount: </p>
              <input
                style={{ width: 100 }}
                className="appearance-none block bg-grey-lighter text-gray-700 border border-gray-300 rounded py-1 px-1 mb-2"
                defaultValue={10.0}
                id="amount"
                type="number"
              />
              <p className="py-1 px-1">SGD</p>
            </div>
            <div className="flex flex-col justify-end">
              <div className="flex items-center text-white relative">
                <label className="flex items-center">
                  <input
                    className="mr-4"
                    type="radio"
                    name="payment-option"
                    defaultValue="paypal"
                    onClick={() => setCheckRadio(1)}
                    checked={checkRadio === 1 ? true : false}
                  />
                  <img src="images/paypal.png" alt="Pay with Paypal" />
                </label>
              </div>
              <div className="mt-2">
                <div className id="paypal-button">
                  <PayPalButton
                    createOrder={(data, actions) => createOrder(data, actions)}
                    onApprove={(data, actions) => onApprove(data, actions)}
                  />
                </div>
              </div>
              <div className="flex items-center text-gray-500 relative">
                <label className="mt-5 flex items-center">
                  <input
                    className="mr-4"
                    type="radio"
                    name="payment-option"
                    defaultValue="card"
                    onClick={() => setCheckRadio(2)}
                    checked={checkRadio === 2 ? true : false}
                  />
                  <img
                    className="w-4/5"
                    src="images/cards.png"
                    alt="Accepting Visa, Mastercard, Discover and American Express"
                  />
                </label>
              </div>
              <div className="mt-2">
                <div className id="card-button">
                  <PayPalButton
                    createOrder={(data, actions) => createOrder(data, actions)}
                    onApprove={(data, actions) => onApprove(data, actions)}
                  />
                </div>
              </div>
            </div> */}
          </>
        ) : (
          // <DropInPayment
          //   authorizationToken={props.loginDetails.authToken}
          //   handlePaymentMethod={hanldePayment}
          //   onError={transactionError}
          //   buttonText="Proceed to Payment"
          //   transactionFailed={props.transactionDetails.transactionSuccessFlag}
          //   classProp="dropPayment"
          //   showConsent={true}
          //   checkedCheckbox={props.transactionDetails.checkconsent}
          //   loaderFunction={() => {
          //     props.changeTransactionValues("loader", "none");
          //     props.changeTransactionValues("paymentLoader", "block");
          //   }}
          //   onChange={(e) => {
          //     props.changeTransactionValues("checkconsent", e.target.checked);
          //   }}
          // />
          <div></div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data) => dispatch(transActions.fetchTransaction(data)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user, product) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user, product)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
