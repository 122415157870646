import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Text, Button, DropInPayment, RadioButton } from "@StixNext/mojito-ui";

import passimage from "../../assets/images/pass_image.png";

import { theme } from "./../../Theme";
import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import * as prodActions from "../../actions/Products";

function BuySingle(props) {
  useEffect(() => {
    props.fetchProducts();
  }, []);
  return (
    <>
      <div
        style={{
          padding: "10px 0px",
        }}
      >
        <Grid>
          <Row>
            <Column xl={5} xs={12}>
              <img className="main_image" src={passimage} alt="icon" />
            </Column>
            <Column
              xl={7}
              xs={12}
              className={"payment-section-text"}
              style={{ flexFlow: "column", alignItems: "flex-start" }}
            >
              <Row>
                <Text as="h4" className="pass_head">
                  Please select your preferred digital concert hall pass
                </Text>
              </Row>
              {props.productDetails.singlePassList.length > 0 &&
                props.productDetails.singlePassList.map((o, k) => {
                  return (
                    <Row className="product_section" key={k}>
                      <Column xl={8}>
                        <RadioButton
                          className="season_radio"
                          labelText={o.label}
                          value={o.price}
                          name="radio"
                          id={o.id}
                          onChange={(e) => {
                            var selectedProduct = props.productDetails.singlePassList.filter(
                              function (item) {
                                return item.id === e.target.id;
                              }
                            );
                            props.changeProductValues(
                              "selectedProduct",
                              selectedProduct[0]
                            );
                            props.changeTransactionValues(
                              "productPrice",
                              e.target.value
                            );
                          }}
                        >
                          {o.description ? (
                            <span className="info_section">
                              {o.description}
                            </span>
                          ) : null}
                        </RadioButton>
                      </Column>
                      <Column xl={2} sm={3} className="pass_price">
                        <Text as="h6">
                          <strong>{`S$${o.price}`}</strong>
                        </Text>
                      </Column>
                    </Row>
                  );
                })}
              <Row style={{ width: "100%", justifyContent: "flex-end" }}>
                <Button
                  className="buy_btn"
                  primary
                  disabled={
                    props.transactionDetails.productPrice ? false : true
                  }
                  onClick={(e) => {
                    props.changeTransactionValues("loader", "block");
                    props.changeTransactionValues("paymentLoader", "none");
                    props.changeTransactionValues("transactionStatus", 2);
                  }}
                >
                  Buy Now
                </Button>
              </Row>
            </Column>
          </Row>
        </Grid>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, clicked) =>
    dispatch(transActions.fetchTransaction(data, clicked)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuySingle);
