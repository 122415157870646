import moment from "moment";

const init = {
  venueList: [],
  dateSelected:moment().format('MM/DD/YYYY'),
};

export const Venue = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_VENUE_VALUE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
