import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Text,
  Button,
  BouncerContextHook
} from "@StixNext/mojito-ui";
import ReactHtmlParser from "react-html-parser";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import * as progrmmeActions from "../actions/Programmes";
import * as VideoOnDemand from "../actions/VideoOnDemand";
import { VideoRestriction } from "../components/Transaction/VideoRestriction";
import { deviceDetect } from "react-device-detect";
import publicIp from "public-ip";

const surfLimit = 145 * 60; //seconds

function VODRedeem(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [showAgree, setShowAgree] = useState(false);
  const loginContext = BouncerContextHook();
  const { keycloak } = props.loginDetails;
  const { sessionId } = keycloak;
  const [seconds, setSeconds] = useState(surfLimit);
  const dacast = window.dacast;
  const showProceed = new Date() > new Date("2021-07-19 10:00");
  const playerEl = useRef(null)

  useEffect(() => {
    let progammeId = props.match.params.id;
    props.fetchVideoOnDemandDetail(progammeId);
    return () => {
      props.changeVideoOnDemandValues("videoOnDemandDetail", []);
    };
  }, []);

  useEffect(() => {
    if (props.loginDetails.authenticated) {
      async function redeemStatus() {
        const timestamp = +new Date();
        const useragent = deviceDetect();
        let ipv4 = await publicIp.v4();
        let session = { sessionId, timestamp, useragent, ip: ipv4 };
        let passId = props.match.params.id;
        let status = await props.fetchRedeemStatus(
          props.loginDetails.user,
          session,
          passId
        );
        //console.log("status", status);
        if (status.ttl && status.ttl > 0) {
          props.changeVideoOnDemandValues("videoOn", true);
          setSeconds(status.ttl * 60);
        } else if (status.ttl === 0) {
          setErrorMessage("The video has ended!");
          setModalOpen(true);
        } else if(status.ttl && status.ttl === -1) {
          localStorage.removeItem("watchTime")
          setShowAgree(true)
        } else  {
          setErrorMessage(status.message);
          setModalOpen(true);
        }
      }
      redeemStatus();
    }
  }, [props.loginDetails.authenticated]);

  useEffect(() => {
    if (props.videoOnDemand.videoOn) {
      if (seconds < 1) {
        setErrorMessage("The video has ended!");
        setModalOpen(true);
        setTimeout(() => props.loginDetails.keycloak.logout(), 5000);
      } else {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      }
    }
  }, [seconds, props.videoOnDemand.videoOn]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const redeemAPi = async (e) => {
    e.preventDefault();
    const timestamp = +new Date();
    const useragent = deviceDetect();
    let ipv4 = await publicIp.v4();
    //let ipv6 = await publicIp.v6()
    let session = { sessionId, timestamp, useragent, ip: ipv4 };
    let passId = props.match.params.id;
    if (props.loginDetails.user.userid && passId) {
      let res = await props.fetchVideoOnRedeem(
        props.loginDetails.user,
        session,
        passId
      );
      if (res.count) {
        props.changeVideoOnDemandValues("videoOn", true);
      } else if (res.message) {
        setErrorMessage(res.message);
        setModalOpen(true);
      } else {
        setErrorMessage("Unable to process your request");
        setModalOpen(true);
      }
    }
  };

  useEffect(() => {
    if (props.videoOnDemand.videoOn) {
      var player = dacast("23234738", "player", {
        "provider": "vzaar",
        "relayGAEventsToParent": true,
        "showControls": "no-time-control",
        "playsinline": false
      })

      player.onReady(() => {
        player.onPlay(function(){
          let wT = localStorage.getItem("watchTime");
          if (wT) {
            let watchTime = JSON.parse(wT);
            player.seekTo(watchTime);
          }
          player.addEventListener("EVENT_TIME_UPDATE", (e) => {
            localStorage.setItem("watchTime", e);
          });
        });
      });
    }
  }, [props.videoOnDemand.videoOn]);

  return (
    <>
      <div className="row vodContainer">
        <div className="container">
          <div className="wholediv">
            <div className={`videodiv voddiv disablechat" ${playerEl.current? "active-video" : ""}`}>
              {!loginContext.sessionRestrictor &&
                props.loginDetails.authenticated &&
                props.videoOnDemand.videoOn &&
                !errorMessage && (
                  <div id="player" ref={playerEl}></div>
                )}
                {loginContext.sessionRestrictor ||
                  props.loginDetails.authenticated === null ||
                  props.loginDetails.authenticated === false ||
                  props.videoOnDemand.videoOn === false ? (
                  <div className="wrap_div ">
                    <div>
                      <Text as="h6" className="redeem_title">
                        <strong>
                          {
                            "JJ Lin 「SANCTUARY FINALE」 Virtual Concert Single View Broadcast  | JJ 林俊傑《聖所 FINALE》終點站 線上演唱會限定轉播"
                          }
                        </strong>
                      </Text>
                        <p className="bold">
                          [ IMPORTANT NOTICE | 觀看須知 ]
                        </p>
                        <ul className="wrap_ul">
                  <li>
                    Please log in using your SISTIC account with your assigned link.
                  </li>
                  <li>
                    Please note that after you log in, you must click "I Agree" to access the stream. The ticket will be deemed as utilised only after you click the "I Agree" button. <strong><span className="red_font">Please proceed to log in ONLY when you are ready to view, as you can only view the concert once. </span></strong>After log in, once the link is activated, you must complete the viewing. The duration of the video is 125 minutes.
                  </li>
                  <li>
                    If you experience internet  connection issues during the session, try refreshing the page, or reduce the quality of your video to 720p and you will be able to continue viewing from where you dropped off.<strong><span className="red_font"> Do not close your browser or log off your account as it will invalidate your pass and prevent you from continuing to watch. (Note: The time buffer for connectivity issues is 10 mins, please refrain from unnecessary refreshing)</span></strong>
                  </li>
                  <li>
                    To ensure compliance to copyright requirements, <strong><span className="red_font">no playbacks, scrubbing or pauses are allowed.</span></strong>
                  </li>
                  <li>
                     The link is available for viewing anytime before 2 August 2021, 11:59pm (GMT +8) and will expire after. 
                  </li>
                  <li>
                    Ticket holders who are not able to watch the Single View Broadcast during the stated period may share the link with a friend. Reselling your ticket is strictly prohibited and your link would be forfeited should you do so.
                  </li>
                  <li>
                    Chromecast and TV browsers are NOT supported for the single-view broadcast. Android users who would like to connect their devices to their TV can use the screenshare option or via HDMI cable.
                  </li>
                  <li>
                    Any form of recording, screenshot and/or mirroring is strictly not allowed. Reproduction and distribution of content without the copyright owner's consent constitutes an infringement of copyright. If you are caught illegally streaming this content, you could be served legal sanction for copyright violation.
                  </li>
                </ul>
                  <p className="heading">If you encounter any technical difficulties at any stage, please contact us feedback@sistic.com.sg or call us at +6348 5555 for assistance. </p>
                    <ul className="wrap_ul">
                  <li>
                    <span>您所收到的專屬鏈接，<strong><span className="red_font">僅提供一次性的觀看 </span></strong>，敬請留意。</span>
                  </li>
                  <li><span>點擊您的專屬鏈接，登入帳號密碼後，按下 "I Agree"（我同意）即可開始觀看，此步驟完成後視為專屬鏈結啟用時間點。影片長度為125分鐘，請確認您已準備好可以觀看整場演出後，再進行啟用的步驟。</span></li>
                  <li>
                    <span>觀看過程中，如果因網路收訊不佳，請試著「重新整理/刷新網頁」，將可從斷點延續觀看。</span>
                    <strong><span className="red_font">切勿關閉瀏覽器或登出帳號，此舉將會導致影片失效，無法繼續觀看。</span></strong>
                    <span>備註：不論刷新次數，操作時間總長不得超出十分鐘</span>
                  </li>
                  <li>
                    <span>因應版權規範，</span>
                    <strong><span className="red_font">
                      影片將以順播模式進行，不得倒轉、快轉或暫停播放，敬請見諒。
                    </span></strong>
                  </li>
                  <li>
                    <span>您可以在有效期間內的任何時段觀看，有效期間至2021年8月2日晚上11:59 (GMT +8) 截止，逾期將會失去觀看權益。</span>
                  </li>
                  <li>
                    <span>
                      無法在有效期間前觀看演出的持票人，可以將鏈接轉贈給家人或朋友觀看。但轉賣您的專屬鏈接是嚴厲禁止的。違反者之專屬鏈接將會被註銷。
                    </span>
                  </li>
                  <li>
                    <span>
                      演出內容嚴禁螢幕翻攝、錄製。未經著作權人的同意擅自發布、加工或重複使用內容的行為屬於侵權行為。若被發現非法播放或任何形式分享此演出，您將會受到侵犯著作權相關的法律制裁。
                    </span>
                  </li>
                </ul>
                  <p className="heading">如有疑問，請電郵至 feedback@sistic.com.sg 或撥打 +65 6348 5555 與 SISTIC 客戶服務聯繫。</p>
                    </div>
                    <div className="button-set">
                    <div>
                      <Link
                        to="/FAQ"
                        target="#"
                        style={{
                          textDecoration: "none",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Button primary className="video_btn faq-button">
                          View FAQ 常見問題
                        </Button>
                      </Link>
                    </div>
                    <div>
                      <Button
                        primary
                        className="video_btn accept-button"
                        disabled={ 
                          props.loginDetails.authenticated && showAgree
                          ? false : true }
                        onClick={(e) => {
                          redeemAPi(e);
                        }}
                      >
                        I AGREE / 我同意
                      </Button>
                      </div>

                    </div>
                  </div>
                ) : (
                  ""
                )}
            </div>
          </div>
        </div>
        <div className="synopiscontainer">
          <div className="synopisdiv">
            <div className="redeemsynopissection">
              <Text as="h6" className="festure-presenter">
                [ IMPORTANT NOTICE | 觀看須知 ] 
              </Text>
              <br />
          
                <ul>
                  <li>
                    <span>
                       <strong><span className="red_font">Please click on the play button on the video player to start viewing. If your player does not show the button, please refresh the page and click on the video to start playing.</span></strong>
                    </span>
                    <br /><br />
                  </li>
                  <li>
                    <span>
                       If you experience internet  connection issues during the session, try refreshing the page and you will be able to continue viewing from where you dropped off. <strong><span className="red_font">Do not close your browser or log off your account as it will invalidate your pass and prevent you from continuing to watch.</span>(Note: The time buffer for connectivity issues is 10 mins, please refrain from unnecessary refreshing)</strong>
                    </span>
                    <br /><br />
                  </li>
                  <li>
                    <span>
                      To ensure compliance to copyright requirements,<strong><span className="red_font"> no playbacks, scrubbing or pauses are allowed. </span></strong>
                    </span>
                    <br /><br />
                  </li>
                  <li>
                    <strong><span className="red_font">Chromecast is not supported for the single-view broadcast. Android users who would like to connect their devices to their TV can use the screenshare option or via HDMI cable. </span></strong>
                  </li>
                  <br />
                  <li>
                    <span>
                      Any form of recording or screenshot is strictly not allowed. Reproduction and distribution of content without the copyright owner's consent constitutes an infringement of copyright. If you are caught illegally streaming this content, you could be served legal sanction for copyright violation.
                    </span>
                    <br />
                  </li>
                </ul>
                <br/>
                If you encounter any technical difficulties at any stage, please contact us feedback@sistic.com.sg or call us at +6348 5555 for assistance.
                <br/>
              <br />
             
                <ul>
                  <li>
                    <span>
                       <strong><span className="red_font">請點擊視頻播放器上的播放按鈕以開始觀看。如果您的視頻播放器未顯示按鈕，請刷新頁面並點擊視頻以播放。</span></strong>
                    </span>
                    <br /><br />
                  </li>
                  <li>
                    <span>
                      觀看過程中，如果因網路收訊不佳，請試著「重新整理/刷新網頁」，將可從斷點延續觀看。<span class="red_font">切勿關閉瀏覽器或登出帳號，此舉將會導致影片失效，無法繼續觀看。</span>備註：不論刷新次數，操作時間總長不得超出十分鐘
                    </span>
                    <br /><br />
                  </li>
                  <li>
                    <span>因應版權規範，</span>
                    <strong><span class="red_font">
                      影片將以順播模式進行，不得倒轉、快轉或暫停播放，敬請見諒。
                    </span></strong>
                    <br /><br />
                  </li>
                  <li>
                    <strong><span className="red_font">
                    限定轉播無法使用Chromecast。Android 用戶可以通過螢幕共享或 HDMI 線做投影視頻。
                    </span></strong>
                    <br /><br />
                  </li>
                  <li> 
                    <span>
                      演出內容嚴禁螢幕翻攝、錄製。未經著作權人的同意擅自發布、加工或重複使用內容的行為屬於侵權行為。若被發現非法播放或任何形式分享此演出，您將會受到侵犯著作權相關的法律制裁。
                    </span>
                    <br />
                  </li>
                </ul>
                <br/>
                如有疑問，請電郵至 feedback@sistic.com.sg 或撥打 +65 6348 5555 與 SISTIC 客戶服務聯繫。
            </div>
            <div className="chatsection">
              <div className="videoDetailFeature">
                <Text as="h4" className="artist">
                  <b>FEATURE ARTIST</b>
                </Text>
                <Text as="h6" className="festure-presenter">
                  {ReactHtmlParser(
                    `About JJ Lin`.replace(/(?:\r\n|\r|\n)/g, "<br />")
                  )}
                </Text>
                <Text as="p">
                  {ReactHtmlParser(
                    `JJ Lin is a singer-songwriter/producer who emerged to award-winning success with his 2003 debut, Music Voyager. His popularity soared throughout Asia with his\nconsistent release of award-winning chart toppers throughout his illustrious career of 17 years, making him one of the most sought-after artistes in the region. \n\n Coined “The Walking CD” by netizens, JJ has performed to 1.6 million spectators spanning 125 tour stops worldwide, and accumulated more than 120 billion streams on music platforms such as QQ Music, Spotify, Apple Music, KKBOX and etc to date. \n\n A versatile performer, JJ’s portfolio of exciting music collaborators include Jason Mraz, Jung Yong-Hwa of CNBLUE, MAYDAY, Luis Fonsi, Ayumi Hamasaki, G.E.M,\n Jam Hsiao, Hiroshi Fujiwara, Steve Aoki, Jackson Wang and Anne Marie. In October last year, a record of 17 million people tuned in for JJ’s new album live streaming concert, a testament to his popularity.\n\n JJ is managed by JFJ Productions worldwide, and represented by United Talents Agency in USA and UK.`.replace(
                      /(?:\r\n|\r|\n)/g,
                      "<br />"
                    )
                  )}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoRestriction
        isOpen={modalOpen}
        errorMessage={errorMessage}
        onClose={closeModal}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    programmeDetails: state.Programmes,
    videoOnDemand: state.VideoOnDemand,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProgrammesValues: (f, e) =>
    dispatch(progrmmeActions.changeProgrammesValues(f, e)),
  changeVideoOnDemandValues: (f, e) =>
    dispatch(VideoOnDemand.changeVideoOnDemandValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
  fetchProgrammeDetail: (id) =>
    dispatch(progrmmeActions.fetchProgrammeDetail(id)),
  fetchVideoOnDemandDetail: (progammeId) =>
    dispatch(VideoOnDemand.fetchVideoOnDemandDetail(progammeId)),
  fetchVideoOnRedeem: (user, programmeId, session) =>
    dispatch(VideoOnDemand.fetchVideoOnRedeem(user, programmeId, session)),
  fetchRedeemStatus: (user, programmeId, session) =>
    dispatch(VideoOnDemand.fetchRedeemStatus(user, programmeId, session)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VODRedeem);
