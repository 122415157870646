import React, { useState, useEffect } from "react";
import { Modal, Grid, Row, Column, Text } from "@StixNext/mojito-ui";
import main from "../assets/images/main.jpg";

export const IncompatibleBrowser = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props]);

  return (
    <Modal isOpen={isOpen} size="large" onClose={props.onClose}>
      <Modal.Body>
        <div>
          <div>
            <Row>
              <Column>
                <Text as="h4" className="oops_txt">
                  Incompatible Browser
                </Text>
                <br />
                <Text as="h5" className="stream_txt">
                  SISTIC Live is not supported by Internet Explorer . We
                  recommend that you use other browsers such as Google
                  Chrome,Mozila Firefox or Microsoft Edge.
                </Text>
              </Column>
            </Row>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
