const init = {
  productList: [],
  seasonPassList: [
    {
      id: 1,
      label: "Montly Subscription",
      price: "20.00",
    },
    {
      id: 2,
      label: "Yearly Subscription (save $140)",
      price: "100.00",
    },
  ],
  singlePassList: [
    {
      id: 1,
      label: "48Hrs Access",
      price: "12.00",
      description: "starting from the moment you watch",
    },
    {
      id: 2,
      label: "1 Week Access",
      price: "24.00",
      description: "starting from the moment you watch",
    },
  ],
  selectedProduct: {},
  productLoading: true,
};

export const Products = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_PRODUCT_VALUE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
