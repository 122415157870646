import Worker from "workerize-loader!./session_worker"; // eslint-disable-line import/no-webpack-loader-syntax

export default function SessionWorker() {
  this.worker = Worker();
  this.subscribers = [];
  this.multipleSession = false

  this.getWorker = function () {
    return this.worker;
  };

  this.getSession = function () {
    return this.multipleSession
  };


  this.addSession = function (multipleSession) {
    this.multipleSession = multipleSession
    this.notifySubscribers(this.multipleSession);
  };

  this.notifySubscribers = function (change) {
    this.subscribers.forEach((subscriber) => {
      subscriber(change);
    });
  };

  this.subscribe = function (itemToSubscribe) {
    if (this.subscribers.indexOf(itemToSubscribe) > -1) {
      return;
    }
    this.subscribers.push(itemToSubscribe);
  };

  this.unsubscribe = function (itemToUnsubscribe) {
    this.subscribers = this.subscribers.filter(
      (subscriber) => subscriber !== itemToUnsubscribe
    );
  };
 }

export const sessionWorker = new SessionWorker();

sessionWorker.getWorker().addEventListener("message", ({ data }) => {
  if (data.event === "sessionUpdate") {
    sessionWorker.addSession(data.multipleSession);
  }
});