import React, { useState, useEffect } from "react";
import { Modal } from "@StixNext/mojito-ui";
import SessionRestriction from "./Transaction/SessionRestriction";

export const SessionEndModal = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props]);

  return (
    <Modal isOpen={isOpen} size="large" onClose={props.onClose} ariaHideApp={false}>
      <Modal.Body>
        <SessionRestriction />
      </Modal.Body>
    </Modal>
  );
};