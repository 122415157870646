import React, { useEffect, useState } from "react";
import { Shell, Carousel, Card, Text } from "@StixNext/mojito-ui";
import { Column, Grid, Row } from "@StixNext/mojito-ui";
import * as Images from "./common/watch-free";
import * as actions from "../actions/VideoOnDemand";
import { connect } from "react-redux";
const updatedOptions = { year: "numeric", month: "short" };

const Upcomming = (props) => {
  let { videoOnDemandList } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [category, setCategory] = useState([]);

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);
  const handleClick = (eventId = "FP2") => {
    props.history.push(`/voddetail/${eventId}`);
  };

  useEffect(() => {
    props.fetchVideoOnDemand();
  }, []);
  useEffect(() => {
    let cat = [];
    videoOnDemandList.map((video) => {
      let { date } = video;
      if (date) {
        cat.push(new Date(date).toLocaleDateString(undefined, updatedOptions));
      }
    });
    let uniqueCat = cat.sort().filter((v, i, a) => a.indexOf(v) === i);
    // let categories = uniqueCat.map((data)=>{
    //  let result= {title:new Date(data).toLocaleDateString(undefined, updatedOptions),date:data}
    //   return result
    // })
    setCategory(uniqueCat);
  }, [videoOnDemandList]);
  const filterCategory = (data, selectedCategory) => {
    if (
      new Date(data?.date).toLocaleDateString(undefined, updatedOptions) ==
      selectedCategory
    ) {
      return data;
    }
  };

  return <Shell.Body className={"css-carousel"}></Shell.Body>;
};
const mapStateToProps = (state) => ({
  videoOnDemandList: state.VideoOnDemand.videoOnDemandList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchVideoOnDemand: () => dispatch(actions.fetchVideoOnDemand()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Upcomming);
