import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Text, Button } from "@StixNext/mojito-ui";

import failed from "../../assets/images/failed.png";
import { theme } from "./../../Theme";

import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";

function TransactionFailure(props) {
  return (
    <>
      <Grid>
        <Row>
          <Column
            xl={5}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={failed}
              alt="icon"
              style={{ width: "166px", height: "166px", opacity: "0.19" }}
            />
          </Column>
          <Column xl={7} xs={12}>
            <Row>
              <Column xl={12}>
                <Text as="p">
                  <Text as="h6" className="fail_text">
                    <strong>Transaction Failed!</strong>
                  </Text>
                </Text>
                <Text as="p">
                  <Text as="h6" className="failed_txt">
                    Something went wrong with your transaction.
                  </Text>
                </Text>
                <Text as="p">
                  <Text as="h6" className="failed_txt">
                    Please check your network connection or credit card detail
                    and try again.
                  </Text>
                </Text>
                {localStorage.getItem("grouppasscompleted") == "false" ? (
                  <>
                    <Button
                      className="try_again_btn"
                      primary
                      onClick={(e) => {
                        props.changeTransactionValues("loader", "block");
                        props.changeTransactionValues("paymentLoader", "none");
                        props.changeTransactionValues("transactionStatus", 2);
                      }}
                    >
                      Try Again
                    </Button>
                    <div style={{ paddingTop: "32px" }}>
                      <a
                        href="#"
                        style={{
                          color: `${theme.mojito.colors.primaryMagnolia}`,
                        }}
                        onClick={(e) => {
                          e.preventDefault();

                          props.changeTransactionValues("isOpen", false);
                          props.changeTransactionValues("hideBuyButton", false);
                          props.changeTransactionValues(
                            "showDropinComponent",
                            false
                          );
                          props.changeTransactionValues("transactionStatus", 1);
                          props.changeTransactionValues("checkconsent", false);
                          localStorage.setItem("buttonClicked", false);
                        }}
                      >
                        No Thanks
                      </a>
                    </div>
                  </>
                ) : (
                  <div></div>
                )}
              </Column>
            </Row>
          </Column>
        </Row>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data) => dispatch(transActions.fetchTransaction(data)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionFailure);
