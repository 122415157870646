import { database } from ".";

let db = database;

// if (process.env.NODE_ENV === "development") {
//   db.useEmulator("localhost", 9000);
// }

export const getSessionMessages = db.ref(`/messages`);

export const getSessionReactions = db.ref(`/reactions`);

export const getSessionDetails = (userId) => db.ref(`/sessions/` + userId);

export const getUserSession = db.ref(`/usersession`);
